.page-homepage {
  .header {
    height: 32px;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 5px;
    line-height: 35px;
  }
}

.mainpage-header {
  .map_wrapper {
    position: relative;
    min-width: 100px;
    min-height: 200px;

    .map-hp {
      border-radius: var(--border-radius-small);
      display: inline-block;
      height: 200px;
      width: 100%;
      // border: 2px solid var(--border-grey);
      border-radius: var(--border-radius-big);
    }

    .map_overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
}

.mainpage-header-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: var(--base-padding) 0 0 0;

  .header-icon {
  }

  .mainpage-header-txt {
    height: 32px;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 5px;
    line-height: 35px;

    img {
      height: 20px;
      margin-right: 10px;
      position: relative;
      top: 3px;
    }
  }

  .mainpage-header-btn {
    color: var(--blue-grey-blue-grey-500);
    font-size: 12px;
    // text-decoration: underline;
    font-weight: 500;
    margin: 0px;
    margin-bottom: 5px;
    line-height: 150%;
    letter-spacing: 0.3px;

    &::part(native) {
      padding: 0 0 0 20px;
    }

    // &.location-btn {
    //   margin-right: -16px;
    // }
  }
}

.city_search.sc-ion-searchbar-ios-h {
  height: 44px;
  margin: 5px 1px 6px 0px;
  padding: 0px;
  input.searchbar-input {
    background: #fff;
    padding-right: 5px;
    -webkit-padding-start: 38px !important;
    padding-inline-start: 38px !important;
  }

  .searchbar-input-container {
    height: 44px;
  }

  &.searchbar-has-value .searchbar-clear-button {
    height: 32px;
    width: 32px;
    padding-left: 7px;
  }

  ion-icon.searchbar-search-icon {
    left: 10px;
  }

  input.searchbar-input {
    border: 1px solid var(--ion-color-primary);
  }
}

.slide-icon-state {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  right: 6px;
  top: -31px;

  ion-icon {
    width: 18px;
    height: 18px;
    margin-top: 1px;
    margin-left: -5px;

    &.slide-icon-organiser {
      margin-top: -1px;
    }
  }
}

// .hp-slides-boxes {
//   display: flex;
//   margin-bottom: var(--padding-big);

//   .swiper-wrapper {
//     // gap: var(--items-spacing-padding);
//   }

//   .swiper-slide {
//     width: 155px;
//     margin-right: var(--items-spacing-padding);

//     &:last-of-type {
//       margin-right: 0;
//     }
//   }

//   .slides-box {
//     width: 155px;
//     height: 187px;
//     border-radius: 24px;
//     padding: 12px 12px 12px 12px;
//     text-align: left;
//     color: #fff;
//     font-size: 18px;
//     font-weight: 700;
//     line-height: 22px;
//     letter-spacing: 0.1px;
//     background-size: 47px;

//     .icon {
//       width: 47px;
//       text-align: center;

//       img {
//         height: 32px;
//       }
//     }

//     &.purple {
//       background: linear-gradient(
//           153.51deg,
//           rgba(255, 255, 255, 0.4) 8.17%,
//           rgba(255, 255, 255, 0) 50.29%
//         ),
//         linear-gradient(0deg, #9e35e5, #9e35e5);
//     }
//     &.blue {
//       background: linear-gradient(
//           153.51deg,
//           rgba(255, 255, 255, 0.4) 8.17%,
//           rgba(255, 255, 255, 0) 50.29%
//         ),
//         linear-gradient(0deg, #359be5, #359be5);
//     }
//     &.pink {
//       background: linear-gradient(
//           153.51deg,
//           rgba(255, 255, 255, 0.4) 8.17%,
//           rgba(255, 255, 255, 0) 50.29%
//         ),
//         linear-gradient(0deg, #e135e5, #e135e5);
//     }
//     &.red {
//       background: linear-gradient(
//           153.51deg,
//           rgba(255, 255, 255, 0.4) 8.17%,
//           rgba(255, 255, 255, 0) 50.29%
//         ),
//         linear-gradient(0deg, #e53555, #e53555);
//     }
//   }
// }
