h1 {
  color: var(--blue-grey-blue-grey-900);
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 36px */
  letter-spacing: 0.2px;
  text-align: left;
  margin-top: var(--small-padding);
}

h2 {
  color: var(--blue-grey-blue-grey-900);
  font-size: 24px;
  font-weight: 600;
  line-height: 130%; /* 31.2px */
  letter-spacing: 0.2px;
}

h3 {
  color: var(--blue-grey-blue-grey-900);
  font-size: 18px;
  font-weight: 600;
  line-height: 140%; /* 25.2px */
  letter-spacing: 0.2px;
}

h4 {
  color: var(--blue-grey-blue-grey-900);
  font-size: 16px;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.3px;
  margin-top: var(--space-2xl);
  margin-bottom: var(--space-l);
  // TODO: only somewhere
  // margin-left: var(--space-2xs);
}

h5 {
  margin: 10px 5px 0 5px;
}

.text-14 {
  color: var(--blue-grey-blue-grey-700);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.4px;
}

.text-important {
  color: var(--blue-grey-blue-grey-500);
  font-size: 16px;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: 0.3px;
}

.notification_dot {
  height: 7px;
  width: 7px;
  background-color: var(--ion-color-danger);
  border-radius: 50%;
  display: inline-block;
}

ion-avatar {
  position: relative;

  .notification_dot {
    position: absolute;
    top: 2px;
    right: -2px;
  }
}

ion-segment {
  .notification_dot {
    position: absolute;
    top: 5px;
  }
}

ion-back-button {
  padding-left: 5px;
  padding-right: 10px;
}

ion-button {
  margin: 0;

  &.back::part(native) {
    background-color: transparent;
  }

  &.button.button-small.button-outline:not(:active) {
    color: var(--ion-color-dark);
  }

  &:not(.button-outline):not(.button-clear)::part(native) {
    --background: var(--color-purple-500);
  }

  &.button-primary-light {
    &::part(native) {
      background-color: var(--ion-color-primary-light);
    }
  }

  &.button-icon {
    ion-icon {
      margin-right: 5px;
      margin-bottom: 3px;
    }
  }

  &.ion-color-primary {
    &::part(native) {
      color: var(--color-purple-25);
    }
  }
  &.ion-color-light {
    &::part(native) {
      color: var(--blue-grey-blue-grey-500);
      background-color: var(--blue-grey-blue-grey-050);
    }
  }
}

.button-tile {
  &.button-primary-light {
    background-color: var(--ion-color-primary-light);
  }
}

ion-button::part(native) {
  --border-radius: var(--border-radius-big);
}

.content-button {
  min-height: calc(100% - 88px);
  position: relative;
  z-index: 3;
}

.big_button_wrapper {
  text-align: center;
  position: sticky;
  z-index: 3;
  bottom: 0;
  width: 100%;
  height: 88px;
  padding: 20px 0 20px 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);

  &.low {
    height: 68px;
    padding-bottom: 0;
  }
}

.modal_big_button {
  height: 48px;
  margin: 0 auto;

  // margin-left: 10px;
  // margin-right: 10px;
  // height: 50px;

  &::part(native) {
    padding: 18.5px 20px;
    width: auto;
  }

  &:not(.button-outline):not(.button-clear):not(.button-disabled)::part(
      native
    ) {
    // --background: var(--ion-color-primary-gradient);
    --background: var(--color-purple-500);
  }

  &.button-disabled {
    opacity: 1;

    &::part(native) {
      // --background: var(--ion-color-primary-gradient);
      background: var(--blue-grey-blue-grey-050);
      color: var(--blue-grey-blue-grey-400);
    }
  }

  ion-icon {
    margin-right: 8px;
  }
}

.default_padding {
  padding: 10px;
}

.community_list {
  ion-item.item {
    //padding: 5px 0 5px 0;
    padding: 0;
    min-height: 70px;

    h3 {
      font-size: 16px;
    }

    &.highlighted {
      --background: var(--ion-color-primary-light-5-alpha);

      ion-label h3 {
        font-weight: bold;
      }
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid var(--border-light);
    }

    ion-buttons {
      margin-top: 5px;
      margin-right: 0;
    }

    ion-button:not(.ion-color-dark) {
      height: 40px;

      &::part(native) {
        background-color: var(--ion-color-primary-light);
        padding-left: 10px;
        padding-right: 10px;
      }

      ion-icon {
        padding-right: 0;
      }

      &.ion-color-medium {
        &::part(native) {
          background-color: var(--ion-color-dark-light);
        }
      }
    }

    ion-avatar {
      width: 53px;
      height: 53px;
      margin-right: 15px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    }
    ion-label {
      min-height: 59px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  ion-checkbox {
    margin-top: 5px;
    margin-bottom: 5px;
    h2 {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 2px;
      font-size: 17px;
      font-weight: normal;
    }
    .label_note {
      color: var(--text-light);
      font-size: 12px;
      margin-top: 5px;

      &.has_unreads {
        font-weight: bold;
        color: #555;
      }

      ion-icon {
        color: #888;
        vertical-align: -10%;
      }
    }

    &::part(container) {
      border-radius: 6px;
      height: 26px;
      width: 26px;
    }
  }
  ion-label {
    margin-top: 5px;
    margin-bottom: 5px;
    h2 {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 2px;
      font-size: 17px;
      font-weight: normal;
    }
    .label_note {
      color: var(--text-light);
      font-size: 12px;
      margin-top: 5px;

      &.has_unreads {
        font-weight: bold;
        color: #555;
      }

      ion-icon {
        color: #888;
        vertical-align: -10%;
      }
    }

    &::part(container) {
      border-radius: 6px;
      height: 26px;
      width: 26px;
    }
  }

  ::part(native) {
    padding-left: 15px;
    padding-right: 0;
  }
}

.default_list {
  ion-item {
    padding: 5px 0 5px 0;

    // &:not(:last-of-type) {
    //   border-bottom: 1px solid var(--border-light);
    // }

    ion-label {
      .label_note {
        color: #666;
        font-size: 12px;
      }
    }
  }
}

.events_list,
.thumbnail_list,
.community_list,
.search_extras {
  ion-item,
  ion-item-sliding {
    .badges {
      position: absolute;
      right: -2px;
      bottom: -2px;
      display: flex;

      ion-badge {
        position: static;
      }
    }

    ion-badge {
      position: absolute;
      bottom: 0px;
      right: 0px;
      padding: 0px;
      border-radius: var(--border-radius-big);
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

      ion-icon {
        padding-right: 0px;
      }

      &.badge-organiser,
      &.badge-admin {
        padding-bottom: 2px;
      }
    }

    .badge_visibility {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background-color: white;
      text-align: center;
      line-height: 22px;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

      ion-icon {
        color: var(--ion-color-primary);
        height: 15px;
      }
    }
  }
}

.find_community {
  padding-bottom: 2px;
  height: 50px;
}

.button_action {
  margin-top: 5px;
  margin-left: 0px;
  margin-right: 0px;
  height: 35px;
  //width: 100%;
  font-size: 16px;

  /*&::part(native) {
    padding: 0px 50px;
  }*/
}

ion-popover {
  ion-icon {
    margin-right: 10px;
  }
}

ion-content {
  ion-toolbar {
    h4 {
      margin-left: 6px;
    }

    &.inner {
      h5 {
        margin-top: 0;
      }

      ion-button {
        margin-left: 5px;
      }
    }
  }
}

.spinner {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

ion-badge,
ion-item-option {
  &.ion-color-danger {
    //background-color: rgba($color: #eb445a, $alpha: 0.7);
    //background: linear-gradient(145deg, #ad2424 0%, #ad2466 100%);
    background: var(--ion-color-danger-gradient);
  }
  &.ion-color-warning {
    color: #333;
    //background-color: #ffc4099c;
    //background: linear-gradient(145deg, #fcbe47 0%, #ef7f08 100%);
    background: var(--ion-color-warning-gradient);
  }
  &.ion-color-primary {
    background: var(--ion-color-primary-gradient-light);
  }
  &.ion-color-medium {
    //background-color: #92949cbf;
    //background: linear-gradient(145deg, #92949c 0%, #6e6f75 100%);
    background: var(--ion-color-medium-gradient);
  }
  &.ion-color-success {
    background: var(--ion-color-success-gradient);
  }
}
/*
ion-item-option {
  &.ion-color-danger {
    background-color: #eb445ac7;
  }
  &.ion-color-warning {
    color: #333;
    background-color: #ffc4099c;
  }
  &.ion-color-primary {
    background-color: #8400ffac;
  }
  &.ion-color-medium {
    background-color: #92949cbf;
  }
  &.ion-color-success {
    background-color: #2dd36fb8;
  }
}*/

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.rmdp-wrapper {
  // TODO: maybe enforce different way
  width: 100% !important;
  border: 0px !important;

  .rmdp-calendar {
    width: 100%;
    padding: 0;

    .rmdp-header {
      height: 40px;
      margin-top: 0px;

      .rmdp-header-values {
        height: 22px;
      }

      .rmdp-arrow-container {
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        margin: 0px;

        .rmdp-arrow {
          border-color: var(--ion-color-primary);
          width: 10px;
          height: 10px;
        }

        &.disabled .rmdp-arrow {
          border-color: var(--border-grey);
        }

        &:hover {
          background-color: transparent;
          box-shadow: none;

          .rmdp-arrow {
            border-color: var(--ion-color-primary);
          }
        }
      }
    }

    .rmdp-day-picker {
      padding: 0px;

      justify-content: center;

      > div {
        width: 100%;
      }

      .rmdp-week {
        width: 100%;
      }

      .rmdp-day,
      .rmdp-week-day {
        width: 100%;
        min-height: 44px;
        @media only screen and (min-width: 380px) {
          min-height: 52px;
        }
        @media only screen and (min-width: 450px) {
          min-height: 60px;
        }
      }

      .rmdp-range {
        color: black;
        background-color: var(--ion-color-primary-light);
        box-shadow: none;

        &.start {
          border-radius: var(--border-radius-small) 0 0
            var(--border-radius-small);
        }

        &.end {
          border-radius: 0 var(--border-radius-small) var(--border-radius-small)
            0;

          &.start {
            border-radius: var(--border-radius-small);
          }
        }
      }

      .rmdp-day {
        line-height: 80%;

        span {
          border-radius: var(--border-radius-small);
        }

        &.rmdp-today {
          span {
            color: black;
            font-weight: bold;
            background-color: white;
          }
        }

        &.rmdp-disabled span .today {
          color: black;
          font-weight: bold;
        }
        &.rmdp-disabled {
          span .current-event {
            background-color: var(--border-light);
          }
        }

        &.rmdp-selected {
          span:not(.highlight) {
            color: black;
            background-color: var(--ion-color-primary-light);
            box-shadow: none;
          }

          span.option-selected {
            background-color: rgba(138, 42, 181, 0.3);
            border: 1px solid var(--border-light);
          }

          span.event_voting_option_won {
            border: 1px solid var(--ion-color-primary);
          }

          span.option-selectable {
            background-color: var(--border-light);
          }

          .note {
            white-space: nowrap;
            font-size: 9px;
            margin-top: 5px;

            div {
              display: inline;

              &.number {
                font-size: 12px;
              }
              &.text {
                font-size: 10px;
              }
            }
          }
        }

        span:hover {
          background-color: inherit;
          color: inherit;
        }
      }

      .rmdp-week-day {
        height: 6vw;
        font-weight: normal;
        color: var(--text-light);
      }
    }
  }
}

.compact_header {
  padding-left: 5px;
  font-size: 16px;
  font-weight: bold;
}

.link {
  color: var(--ion-color-primary-text);
}
.onboarding_link {
  color: #ff00a5;
}

.tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.tag {
  display: inline-block;
  font-size: 14px;
  padding: 6px 10px;
  margin-bottom: 3px;
  border-radius: var(--border-radius-mid);
  white-space: nowrap;
  border-radius: 12px;
  background: var(--blue-grey-blue-grey-050, #f3f3f5);
  color: var(--blue-grey-blue-grey-900, #141518);
  font-weight: 600;
  line-height: 140%;
  letter-spacing: 0.2px;
  margin-right: 5px;
}

.mt5 {
  margin-top: 5px;
}

ion-header {
  ion-toolbar {
    display: flex;
  }
}

.main_text {
  font-size: 18px;
  padding: 10px 20px;
  text-align: center;
}

.title_center {
  text-align: center;
  font-size: 20px;
}

.title-wide {
  padding-inline: 34px;
}

.popover_options {
  ion-item {
    &::part(native) {
      font-size: 14px;
      padding: 0px 5px 0px 10px;
    }

    &::part(native) {
      --border-color: var(--border-light);
    }

    &:last-of-type {
      &::part(native) {
        --inner-border-width: 0px;
      }
    }

    &.header {
      &::part(native) {
        color: var(--ion-color-primary);
        background-color: var(--ion-color-primary-light);
        --inner-border-width: 0px;
      }
    }
  }

  ion-icon {
    padding: 1px 10px 3px 0px;
    margin: 0px;
  }
}

.popover_social {
  //--width: auto;
  ion-item {
    &::part(native) {
      font-size: 14px;
      padding: 0px 5px 0px 10px;
    }

    &:last-of-type {
      --inner-border-width: 0px;
    }

    ion-avatar {
      margin-right: 10px;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

ion-fab-button[data-desc] {
  position: relative;
  --border-radius: 0 20px 20px 0;
  --background: var(--ion-color-primary-light);
}

ion-fab-button[data-desc]::after {
  position: absolute;
  content: attr(data-desc);
  z-index: 1;
  right: 40px;
  bottom: 0px;
  background-color: var(--ion-color-primary-light);
  height: 22px;
  line-height: 22px;
  padding: 9px 9px 9px 20px;
  border-radius: 20px 0 0 20px;
  color: var(--ion-color-primary);
}

ion-toast {
  height: calc(100% - 70px);
  --box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
}

.text-light {
  color: var(--text-light);
}

.controls {
  display: flex;
  justify-content: space-between;
}

.container-tags {
  display: flex;
  gap: var(--space-xs);
  flex-flow: wrap;
}

.margin-bottom {
  margin-bottom: var(--space-l);
}

.margin-top {
  margin-top: var(--space-l);
}

.padding-top-s {
  padding-top: var(--space-s);
}
