.verify {
  .code {
    text-align: center;

    ion-input {
      margin: 20px auto;
      width: 50%;
      border: 1px solid var(--border-light);
      border-radius: var(--border-radius-small);
      font-size: 30px;
    }
  }

  ion-row.no_border {
    border-bottom: 0px !important;
  }
}
