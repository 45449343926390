.skeleton {
  &:not(:last-of-type) {
    border-bottom: 1px solid var(--border-light);
  }

  &.skeleton-big {
    &::part(native) {
      padding-left: 10px;
    }

    .skeleton-line {
      margin-left: 45px;

      &:nth-of-type(2) {
        width: 25%;
        margin-left: 45px;
      }

      &:nth-of-type(3) {
        margin-left: 45px;
      }
    }

    .skeleton-avatar {
      width: 72px;
      height: 56px;
      margin-top: -12px;
    }
  }

  .skeleton-line {
    margin-bottom: 7px;
    display: block;
    width: 45%;

    &:nth-of-type(2) {
      width: 90%;
    }

    &:nth-of-type(3) {
      width: 30%;
    }
  }

  .skeleton-avatar {
    --border-radius: 20%;
  }
}

.skeletom-homepage-container {
  margin-top: 20px;
  margin-bottom: 20px;
  .skeleton-homepage-header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .skeleton-homepage-title {
      width: 40%;
    }
    .skeleton-homepage-btn {
      width: 20%;
    }
  }
  .skeleton-homepage-slides {
    margin-top: 10px;
    .skeleton-homepage-event-slide {
      .skeleton-homepage-event-card {
        position: relative;
        width: 100%;
        aspect-ratio: 10 / 6;
        margin: 0 20px 0 0;
        border-radius: var(--border-radius-small);
        .skelton-homepage-thumbnail {
          width: 340px;
          height: 204px;
          aspect-ratio: 10 / 6;
          margin: 0 20px 0 0;
          border-radius: var(--border-radius-small);
        }
      }
    }
  }
}

.skelet-title {
  width: 30%;
}

.skeleton_ev_det {
  padding-bottom: 60px;
  width: 100%;
  height: 309px;

  .skeleton_img {
    width: 100%;
    height: 100%;
  }
}

.thumb_icon_skelet {
  width: 40px;
  height: 40px;
}

.skelet_info_box {
  .skelet_info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40px;
  }
}

.table_skelet {
  width: 100%;
  .skelet_line {
    margin-top: 10px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    display: flex;
    .skelet_btn {
      margin-right: 10px;
    }
  }
}
.txt-skelet {
  padding-top: 100px;

  .skeleton_head_desc {
    height: 20px;
  }
}
