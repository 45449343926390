.list_date {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
  color: rgba($color: #000000, $alpha: 0.65);
  --min-height: 14px;

  &::part(native) {
    padding-left: 10px;
  }

  &:not(:first-of-type) {
    margin-top: 15px;
  }
}

.logo_pic {
  height: 18px;
  margin-left: 8px;
  &.org_web {
    margin-top: 15px;
  }
}

// .event_item + .event_item {
//   border-top: 1px solid var(--border-light);
// }

// .thumbnail_list_header + .event_item {
//   border-top: 1px solid var(--border-light);
// }

ion-item-sliding {
  overflow: visible;

  &.event_item {
    margin: 0px;
    padding: 0px;
    //height: 70px;
  }
}

.event_item {
  position: relative;
  background: white;
  --padding-start: var(--base-padding);
  --padding-end: var(--base-padding);
  --padding-top: var(--small-padding);
  --padding-bottom: var(--small-padding);
  --inner-padding-end: 0;

  &.highlighted {
    ion-label h3 {
      font-weight: bold;
    }

    ion-item {
      --background: var(--ion-color-primary-light-5-alpha);
    }
  }

  /*&ion-item {
    height: 76px;
    padding-top: 3px;
    padding-bottom: 3px;
  }*/

  /*&ion-item-sliding {
    margin: 0px;
    padding: 0px;
    height: 82px;
  }*/

  ion-item {
    border-bottom: 0px;
  }

  ion-item-options {
    border-bottom: 0px;
    .wide {
      border-radius: var(--border-radius-small);
    }
  }

  // &::part(native),
  // ::part(native) {
  //   padding-left: var(--base-padding);
  // }

  ion-label {
    margin-right: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 70px;

    h3 {
      margin-top: 0px;
      color: var(--blue-grey-blue-grey-900);
      font-size: 14px;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
      letter-spacing: 0.2px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .icon_small {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }

    .address {
      color: var(--text-light);
    }

    .event_info_row {
      font-weight: 400;
      display: flex;
      font-size: 11px;
      color: #212121;
      justify-content: space-between;

      .event_info_col {
        margin-top: 2px;
        display: flex;
      }

      .icon {
        color: #8c9295;
      }

      .texts {
        line-height: 17px;
      }

      // &.tags .tag {
      //   margin-top: 1px;
      //   margin-bottom: 1px;
      // }
    }
  }

  ion-avatar {
    margin-right: var(--base-padding);
    width: 80px;
    height: 80px;
    margin-top: 0;
    margin-bottom: 0;

    img {
      border-radius: var(--border-radius-big);
    }
  }
  .cover_area {
    display: flex;

    .cover_photo {
      height: 100px;
      width: 70%;
      object-fit: cover;
      object-position: center top;
    }

    ion-button {
      height: 100px;
      margin: 0px;
      width: 30%;
      letter-spacing: 1px;

      &::part(native) {
        border-radius: 0;
      }
    }
  }

  // &.ion-color.event_item_swipeable {
  //   border-right: 12px solid;

  //   &.ion-color::after {
  //     content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16"><path fill="black" d="m12 15-5-5 5-5 1.062 1.062L9.125 10l3.937 3.938Z"/></svg>');
  //     width: 10px;
  //     height: 12px;
  //     display: block;
  //     position: absolute;
  //     top: 2px;
  //     right: -6px;
  //     z-index: 1;
  //   }

  //   &.ion-color-primary {
  //     border-right-color: var(--ion-color-primary);
  //     &::after {
  //       content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16"><path fill="white" d="m12 15-5-5 5-5 1.062 1.062L9.125 10l3.937 3.938Z"/></svg>');
  //     }

  //     .cover_area {
  //       ion-button::part(native) {
  //         --background: var(--ion-color-primary);
  //       }
  //     }
  //   }

  //   &.ion-color-secondary {
  //     border-right-color: var(--ion-color-secondary);
  //   }

  //   &.ion-color-dark {
  //     &::after {
  //       content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16"><path fill="white" d="m12 15-5-5 5-5 1.062 1.062L9.125 10l3.937 3.938Z"/></svg>');
  //     }
  //   }

  //   &.ion-color-danger {
  //     border-right-color: var(--ion-color-danger);

  //     .cover_area {
  //       ion-button::part(native) {
  //         --background: var(--ion-color-danger);
  //       }
  //     }
  //   }
  //   &.ion-color-warning {
  //     border-right-color: var(--ion-color-warning-shade);

  //     .cover_area {
  //       ion-button::part(native) {
  //         color: black;
  //         --background: var(--ion-color-warning);
  //       }
  //     }
  //   }

  //   &.ion-color-medium {
  //     border-right-color: var(--ion-color-medium-shade);

  //     .cover_area {
  //       ion-button::part(native) {
  //         color: black;
  //         --background: var(--border-grey);
  //       }
  //     }
  //   }

  //   &.ion-color-success {
  //     // not used yet
  //     //border-right-color: #2dd36fb8;
  //   }
  // }

  &_extra_info {
    &.ion-color {
      border-right: 0px;
    }

    ion-avatar {
      display: none;
    }

    ion-label {
      height: auto;

      h3 {
        font-size: 20px;
        margin-top: 5px;
        margin-bottom: 10px;
      }

      .icon_small {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      .event_info_row {
        font-size: 15px;

        .texts {
          line-height: 21px;
        }
      }
    }
  }

  //ion-badge {
  /*position: absolute;
    top: 3px;
    right: 0px;
    font-size: 10px;
    padding-right: 2px;
    border-radius: var(--border-radius-small);
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;*/

  /*
    bottom: 0px;
    left: 0px;
    font-size: 10px;
    width: 65px;
    padding-left: 2px;
    border-radius: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    */

  /*&.ion-color-danger {
      background: transparent;
    background: linear-gradient(to left, rgba($color: #eb445a, $alpha: 0.7), 90%, rgba($color: #eb445a, $alpha: 0));
    }*/
  //}

  .time,
  .address {
    .active {
      color: #1c7541;
    }

    .success {
      color: #1c7541;
    }
    .danger {
      color: #eb445a;
    }
  }
}

// TODO: still used?
ion-grid.event_basic_info {
  font-size: 14px;
  padding: 0px 0px;

  ion-col {
    padding-bottom: 0;
  }
}

.event-flag {
  color: white;
  padding: 3px 10px 4px 10px;
  font-size: 12px;
  letter-spacing: 1px;

  &-organiser {
    background-color: var(--ion-color-danger);
  }

  &-going {
    background-color: var(--ion-color-primary);
  }

  &-organiser,
  &-going {
    &.past {
      background-color: var(--ion-color-medium);
      color: var(--ion-color-dark);
    }
  }
}

.event_attendance_options {
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px;
  min-height: 55px;

  &.event_attendance_invited {
    background-color: var(--ion-color-warning-background);
  }

  &.event_attendance_declined {
    background-color: var(--border-light);

    .buttons {
      width: 30%;
    }
    &.placeDetail_follow_btn {
      ion-button {
        margin-bottom: 10px;
      }
    }
  }

  &.event_attendance_public {
    background-color: var(--ion-color-warning-background);
    .buttons {
      min-width: 60%;
    }
  }

  &.event_attendance_past {
    background: var(--ion-color-light);
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .friends-part-text {
    line-height: 44px;
  }

  .buttons {
    display: flex;

    ion-button {
      height: 44px;
      margin: 0 0 0 10px;
      width: 110px;

      ion-icon {
        width: 25px;
        padding-right: 5px;
        vertical-align: middle;
      }

      &.ion-color-light::part(native) {
        background-color: var(--border-grey);
      }
    }
  }

  ion-accordion-group {
    margin: 0 0 0 10px;
    position: absolute;
    right: 10px;
    z-index: 2;

    ion-icon {
      color: inherit;
      padding-right: 0;
      // margin-left: 5px;
      margin-right: 5px;
    }

    .accordion-item {
      background-color: var(--ion-color-primary-light);
      display: flex;
      align-items: center;
    }
  }
}
.event_alternative_dates {
  display: flex;
  min-height: 40px;
  padding: 0;
  width: calc(100% - 6px);
  border: 1px solid var(--border-grey);
  margin: 3px;
  font-size: 15px;
  border-radius: var(--border-radius-small);
  padding-left: 10px;
  border: 1px solid var(--border-light);
  align-items: center;
  background-color: var(--ion-color-primary-light);
}

.expander {
  color: var(--blue-grey-blue-grey-900, #141518);
  font-size: 14px;
  font-weight: bold;
  line-height: 140%;
  letter-spacing: 0.1px;
  padding: 6px 10px 6px 0;
  display: inline-block;
}

.description {
  color: var(--blue-grey-blue-grey-900, #141518);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.4px;
  position: relative;

  &.short,
  &.collapsed {
    max-height: 100px;
    overflow: hidden;
  }

  &.collapsed {
    &::after {
      content: ""; // ::before and ::after both require content
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      background-image: linear-gradient(0deg, #fff, rgba(255, 255, 255, 0));
    }
  }

  .title {
    font-weight: bold;
    font-size: 16px;
  }
  .line {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  // for the case somebody sends html-code in several lines
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div:not(.line) {
    margin-bottom: 0;
    margin-top: 0;
  }

  h1,
  h2 {
    font-size: 16px;
  }

  // for the case when somebody sends html-code in 1 line
  h1:not(:only-child),
  h2:not(:only-child),
  h3:not(:only-child),
  h4:not(:only-child),
  h5:not(:only-child),
  h6:not(:only-child),
  p:not(:only-child),
  div:not(:only-child):not(.line) {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.alternative_dates {
  margin: 5px 0;
  padding: 0;

  .title {
    font-size: 16px;
    margin-bottom: 3px;
    font-weight: bold;
  }
  .dates_list {
    margin: 0;
    list-style: none;
    padding-inline-start: 0;
  }
}

// .description {
//   padding: 20px 10px 0 10px;
// }

.event_detail {
  ion-title {
    max-width: 240px;
    padding: 0;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .no_attendees {
    color: var(--ion-color-dark);
    width: 100%;
    text-align: center;
    line-height: 32px;
    margin: 10px 0;

    ion-icon {
      height: 30px;
      width: 30px;
      margin-bottom: 6px;
    }
  }
}

.location_icon_box {
  display: flex;
  align-items: center;
}

.swiper.event_boxes-hp {
  padding-left: var(--base-padding);

  &.swiper .swiper-slide {
    width: 70%;

    // &:last-of-type {
    // width: 30%;
    // width: calc(70% - 20px);

    // .event_box {
    //   margin-right: 0;
    // }
    // }
  }
}

.event_boxes-map {
  margin: 0 10px 15px 10px;

  &.swiper {
    .swiper-slide {
      width: calc(85% + 10px);
      height: 112px;

      .event_box_map {
        margin: 0 10px 0 10px;

        .event-card-title-box {
          background-color: #fff;
        }
      }

      &:first-child {
        .event_box_map {
          margin-left: 20px;
        }
      }

      &:last-child {
        .event_box_map {
          margin-right: 20px;
        }
      }
    }
  }

  &.event_boxes-map-single {
    .swiper-wrapper {
      justify-content: center;
    }
  }
}

.event_box_map {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-mid);
  background: white;
  box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.12);
  margin: 10px;
  text-align: left;

  &::part(native) {
    display: flex;
    flex-direction: row;
    font-family: PPTelegraf;
  }

  .image {
    height: 112px;
    width: 35%;
    background-size: cover;
    background-position: center top;

    .slide-date-rectangle {
      padding: 0px 5px 0px 5px;
      min-width: 45px;
      min-height: 60px;
      color: white;
      position: absolute;
      left: 0;
      top: 0;
      border-top-left-radius: var(--border-radius-mid);
      border-bottom-right-radius: var(--border-radius-mid);
      background-color: var(--color-purple-500);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .light {
        color: var(--purple-100, #f9ebfe);
        font-size: 12px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.3px;
      }

      .strong {
        color: var(--blue-grey-blue-grey-000, #fff);
        font-size: 16px;
        font-weight: 800;
        line-height: 120%;
      }
    }
  }

  .event-card-texts {
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: var(--items-spacing-small) var(--items-spacing-padding);

    h3 {
      color: var(--blue-grey-blue-grey-900, #141518);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.2px;
      margin: 0 0 8px 0;
      overflow: hidden;
      text-wrap: nowrap;
    }

    .title {
      color: var(--blue-grey-blue-grey-500, #636878);
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: 0.3px;
    }

    .main {
      color: var(--blue-grey-blue-grey-900, #141518);
      font-size: 12px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.2px;
      overflow: hidden;
      text-wrap: nowrap;
    }
  }
}

.event_box {
  position: relative;
  width: 100%;
  // aspect-ratio: 10 / 6;
  margin: 0 20px 0 0;
  // padding-bottom: 50px;
  box-shadow: none;

  .image {
    width: 100%;
    aspect-ratio: 10 / 7;
    // border: 2px solid var(--border-grey);
    background-position: center top;
    background-size: cover;
    border-radius: var(--border-radius-big);
  }

  // &.color-danger .date {
  //   color: white;
  //   background: var(--ion-color-danger-gradient);
  // }
  // &.color-warning .date {
  //   background: var(--ion-color-warning-gradient);
  // }

  // &.color-primary .date {
  //   color: white;
  //   background: var(--ion-color-primary-gradient-light);
  // }
  // &.color-medium .date {
  //   background: var(--ion-color-medium-gradient);
  // }

  // &.color-secondary .date {
  //   background: var(--ion-color-secondary);
  // }

  // &.color-dark .date {
  //   background-color: var(--border-grey);
  // }

  .event-card-title-box {
    // background-color: var(--ion-background-color-light);
    // position: absolute;
    text-align: start;
    // display: flex;
    // flex-direction: column;
    bottom: 0;
    width: 100%;
    height: 100%;
    color: var(--ion-text-color);
    font-weight: 600;
    // margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .texts {
      border-radius: 0 0 var(--border-radius-big) var(--border-radius-big);
      padding: var(--space-m) var(--space-s) var(--space-xs) var(--space-s);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.7) 50%
      );
    }

    .event-card-title {
      font-size: 16px;
      margin: 10px 10px 3px 0px;
      overflow: hidden;
      color: var(--blue-grey-blue-grey-025);
      // font-size: 18px;
      font-weight: 600;
      line-height: 120%;
      letter-spacing: 0.2px;
    }

    .event-card-subtitle {
      display: block;
      // justify-content: space-between;
      padding-top: -5px;
      padding-right: 10px;
      font-size: 12px;
      color: var(--lighter-70);
      line-height: 120%;

      .text {
        display: none;
      }
    }

    // .event-card-dates-title {
    //   font-size: 11px;
    // }
  }
}

.slide-big-btn-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  aspect-ratio: 10 / 6;
  height: 100%;
  padding-top: 25px;
  font-size: 16px;

  .mainpage-big-btn {
    height: 100px;

    ion-icon {
      width: 80px;
      height: 80px;
      transform: rotate(270deg);
    }
  }
}
