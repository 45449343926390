.feedback {
  // border-radius: var(--border-radius-small);
  // background-color: #f1f1f1;
  padding-bottom: 25px;

  .header-box-fb {
    display: flex;
    justify-content: space-between;

    ion-chip {
      border: transparent;
    }

    .header-chat-fb {
      padding-left: 10px;
      font-weight: 600;
      font-size: 16px;
      margin-top: 10px;
    }
    .time {
      color: #666;
      font-size: 10px;
      margin-top: 10px;
      text-align: end;
    }
  }
  // .main-fb-msg {
  //   padding-left: 10px;
  //   &.top-fb-msg {
  //     padding-top: 10px;
  //   }
  // }

  .hr-fb {
    margin-top: 15px;
    border-bottom: 1px solid #cccccc;
  }

  line-height: 1.35;
  // margin-top: 10px;

  &:first-child {
    padding-top: 20px;
  }

  .fb_big_button {
    padding: 18.5px 20px;
    height: 77px;
    // display: flex;

    &::part(native) {
      width: auto;
    }
  }
}

.fb-answ-comment {
  padding-left: 40px;
}

.textarea-fb-box {
  margin-top: var(--space-l);
  // display: flex;
  // padding: 20px 10px 0 10px;
  // position: relative;
  // flex-direction: row-reverse;

  .submit-fb {
    position: absolute;
    overflow: visible;
    top: 26px;
    z-index: 2;
  }
}

ion-textarea.custom-area-fb {
  // --background: #fff;
  --placeholder-color: #4b4b4b;
  --padding-start: 19px;
  --padding-end: 19px;
  --border-radius: var(--border-radius-mid);

  // border-radius: 12px;
  --border: 0.5px solid var(--input-field-input-field-outline, #dddee3);
  --background: var(--input-field-input-field-bg, #f3f3f5);

  --padding: 13px 19px;

  color: var(--blue-grey-blue-grey-40);
  font-family: PP Telegraf;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.4px;
}
