.full_height {
  display: flex;
  flex-direction: column;
  height: 100%;
}

ion-modal {
  form {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    ion-button {
      display: flex;
    }
  }
}

.form {
  h4 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .mandatory_col {
    &:before {
      content: "*";
      color: var(--ion-color-primary-red);
      position: absolute;
      left: 44px;
      top: 8px;
    }
  }

  .native-input:not(.input-old .native-input) {
    height: 48px;
    // padding: 10px 10px 10px 0;
    padding: 14px 16px;
    font-family: PPTelegraf;
    color: var(--blue-grey-blue-grey-900, #141518);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.4px;

    border-radius: var(--border-radius-mid);
    border: 0.5px solid var(--blue-grey-blue-grey-100);
    background: var(--blue-grey-blue-grey-050, #f3f3f5);
  }

  .form-tags {
    gap: 5px;
  }

  ion-grid {
    padding: 0px;

    // ion-row {
    //   border-bottom: 1px solid #eef1f4;

    //   &.spacer {
    //     border-bottom: 5px solid #eef1f4;
    //   }
    // }
  }

  &.with_icons {
    ion-col {
      &:first-of-type {
        padding-left: 50px;
      }

      &.title {
        padding-right: 45px;
      }
    }
  }

  // &:not(.with_icons) {
  //   ion-col {
  //     &:first-of-type {
  //       padding-left: 10px;
  //     }
  //   }
  // }

  ion-col {
    // &:last-of-type {
    //   padding-right: 10px;
    // }

    &.full_size {
      padding: 0px !important;
      margin: 0px;

      img {
        display: block;
        margin: auto;
      }

      &.profile_photo_preview {
        img {
          margin-top: 10px;
          margin-bottom: 10px;
          border-radius: 50%;
        }
      }
    }
  }

  // TODO: this us ugly, put class on ion icon instead #1
  ion-icon.label_icon {
    color: var(--text-light);
    position: absolute;
    top: 16px;
    left: 18px;
    //margin: 0 20px 0 18px;
    &.hash {
      top: 11px;
    }
  }

  .invitee {
    ion-icon {
      vertical-align: -10%;
      // TODO: remove this when #1 is done
      position: static !important;
    }
  }

  ion-textarea,
  textarea {
    min-height: 38px;
  }

  span,
  div {
    &.error {
      color: var(--ion-color-primary-red);
      font-size: 14px;
    }
  }

  div.error {
    // border-top: 1px solid var(--ion-color-primary-red);
    padding-left: 5px;
  }

  .row_light {
    text-align: center;

    ion-col {
      padding-top: 0;
      padding-bottom: 0;

      &:first-of-type {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    ion-button {
      margin-top: 10px;
    }
  }

  .row_no_line {
    border-bottom: 0;
  }

  .label {
    padding-top: 12px;
    padding-bottom: 10px;
    vertical-align: bottom;
    line-height: 1.5em;

    &.compact {
      line-height: 1em;
    }

    .input_placeholder {
      color: rgba(0, 0, 0, 0.5);
    }

    .input_placeholder + .error {
      margin-top: 8px;
    }
  }

  .right_input_col {
    input {
      margin-left: 20px;
    }

    ion-toggle {
      margin: 3px 0 0 17px;
    }
  }

  .left_select {
    ion-select {
      // padding: 5px 5px 5px 0;
    }
  }

  .big_button {
    width: 100%;
    margin: 0px 0 20px 0;

    &:not(.button-outline):not(.button-clear)::part(native) {
      --background: var(--ion-color-primary-gradient);
      --background-activated: var(--ion-color-primary-shade);

      /*--background: linear-gradient(
        145deg,
        rgba(137, 48, 255, 1) 0%,
        rgba(96, 29, 184, 1) 100%
      );*/
    }

    &.button-outline.ion-activated::part(native) {
      border-width: 0;
    }
  }

  .photo_icon {
    position: absolute;
    right: 2px;
    top: 0px;
    height: 32px;
    width: 32px;
    z-index: 100;
    padding: 8px;
  }

  .datetime_short {
    width: 80px;
    display: inline-block;
  }

  .small_inputs_delimiter {
    display: inline-block;
    height: 20px;
  }

  .label-time {
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
    // &.date-options-time {
    //   padding: 0;
    // }
  }

  ion-segment {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.big_form {
  margin-top: var(--base-padding);

  ion-input {
    height: 40px;
  }

  ion-row:last-of-type {
    border-bottom: 0px;
  }

  ion-select {
    height: 40px;
    min-width: 77px;
  }

  div.error {
    border-top: 1px solid var(--ion-color-primary-red);
  }

  span.error,
  div.error {
    color: var(--ion-color-primary-red);
  }

  .label {
    padding-top: 5px;
    padding-bottom: 5px;
    vertical-align: top;

    ion-icon {
      height: 22px;
      width: 22px;
      display: inline-block;
    }
  }

  .big_button_row {
    border-bottom: 0px;
    text-align: center;

    ion-button {
      max-width: 270px;
      display: inline-block;
    }

    ion-col {
      padding: 0;
    }

    &_alternative {
      ion-col {
        display: flex;
      }

      ion-button {
        margin-top: 0;
      }
    }

    .btn-social {
      margin-bottom: 20px;

      &.btn-social-primary {
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
  }

  .alternative_action {
    border-bottom: 0;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 1px;
    line-height: 20px;
    color: var(--text-light);
    margin-left: 15px;
    margin-right: 15px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) calc(50% - 1px),
      var(--border-grey) calc(50%),
      rgba(0, 0, 0, 0) calc(50% + 1px)
    );
    width: 100%;
    max-width: 270px;
    margin: 0 auto;

    span {
      padding-left: 10px;
      padding-right: 10px;
      background-color: white;
    }
  }

  .navigation {
    text-align: center;
    padding-bottom: 10px;
    font-size: 26px;
    color: var(--text-light);
    //text-transform: uppercase;
  }

  .navigation_bottom {
    text-align: center;
    ion-button {
      font-size: 14px;
      text-decoration: none;
    }
  }

  .header_verify {
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    color: var(--ion-color-primary-shade);
  }

  .code_message {
    text-align: center;
    font-size: 14px;
    color: #333;
  }

  .centered {
    text-align: center;
  }
}

.react-autosuggest__container {
  position: relative;
}

/*.react-autosuggest__input {
  width: 240px;
  height: 30px;
  padding: 10px 20px;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  //border-radius: var(--border-radius-small);
}*/

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  //border-bottom-left-radius: 0;
  //border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 42px;
  right: 0;
  width: 80%;
  //border: 1px solid #aaa;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
  background-color: #fff;
  //font-family: Helvetica, sans-serif;
  //font-weight: 300;
  //font-size: 16px;
  //border-bottom-left-radius: 4px;
  //border-bottom-right-radius: 4px;
  z-index: 100;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 15px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

ion-radio {
  &::part(container) {
    border: 2px solid var(--ion-color-primary);
    height: 25px;
    width: 25px;
  }
}

ion-textarea.sc-ion-textarea-ios-h:not(.input-old) {
  // --background: #fff;
  --placeholder-color: #4b4b4b;
  --padding-start: 19px;
  --padding-end: 19px;
  --border-radius: var(--border-radius-mid);

  // border-radius: 12px;
  --border: 0.5px solid var(--input-field-input-field-outline, #dddee3);
  --background: var(--input-field-input-field-bg, #f3f3f5);

  --padding: 13px 19px;

  color: var(--blue-grey-blue-grey-40);
  font-family: PP Telegraf;
  font-size: 14px;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: 0.4px;
}

ion-toggle {
  --handle-height: 20px;
  --handle-width: 20px;
  --handle-spacing: 2px;

  &::part(track) {
    height: 24px;
    width: 48px;
  }
}
