.onboard-content-page {
  &.onboard-content-page-photo {
    background-image: url("../css_assets/onboarding_main_cover.jpg");
    background-position: 50%;
    background-size: cover;
    --background: none;
  }

  h1 {
    margin-bottom: var(--padding-huge);
  }

  .page-one-body {
    padding-top: calc(var(--base-padding) + env(safe-area-inset-top));
    height: calc(100% - var(--base-padding) - env(safe-area-inset-top));
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .page-one-upper-body-box {
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;

      .cats-words-onboard {
        padding: 30px 0 0 0;
        display: flex;
        justify-content: center;
      }

      .page-one-logo_btn-box {
        // padding-top: 60%;
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;

        .page-one-logo {
          width: 125px;
          height: 32px;
          margin-bottom: 20px;
          background-color: rgba(0, 0, 0, 0.6);
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 25px;
          box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.6);
        }

        .page-one-login-btn {
          color: white;
        }
      }
    }

    .page-one-lower-body-box {
      // bottom: 0;
      // position: fixed;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      .page-one-btn_tac-box {
        color: white;
        width: 100%;
        padding-bottom: m#{a}x(10px, env(safe-area-inset-bottom));
        ion-button.page-one-btn {
          &::part(native) {
            --background: linear-gradient(270deg, #e6028f 0%, #9f2e82 100%);
          }
          width: calc(100% - 2 * var(--base-padding));
          margin: var(--base-padding);
        }
      }
    }
  }

  .onboard-back-btn {
    padding-left: 3px;
  }
  .onboard-header-box {
    text-align: center;
  }

  .events-boxes {
    display: flex;
    flex-wrap: wrap;
    gap: var(--base-padding);
  }

  .img-gradient-box {
    position: relative;
    color: white;
    font-size: 14px;
    border-radius: var(--border-radius-mid);
    overflow: hidden;
    width: calc(50% - var(--base-padding) / 2);
    aspect-ratio: 1;
    overflow: hidden;
    color: var(--blue-grey-blue-grey-025, #fafafb);
    font-size: 18px;
    font-weight: 600;
    line-height: 140%; /* 25.2px */
    letter-spacing: 0.2px;

    .onboard-icon-bg {
      position: absolute;
      top: 8px;
      right: 8px;
      background-color: var(--lighter-20);
      width: 28px;
      height: 28px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      border: 2px solid var(--blue-grey-blue-grey-000);

      &.checked {
        background-color: var(--color-purple-500);

        ion-icon.onboard-icon {
          display: block;
        }
      }

      ion-icon.onboard-icon {
        display: none;
        position: absolute;
        width: 20px;
        height: 20px;
        right: 2px;
        top: 3px;
      }
    }

    .image {
      display: block;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center top;
    }

    .gradient-color {
      border-radius: var(--border-radius-mid);
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0.8) 86.98%
      );

      // &.onboard-checked-cat {
      //   border: 3px solid var(--ion-color-primary);
      // }
    }

    .onboard-tag-name {
      position: absolute;
      bottom: 8px;
      width: 100%;
      text-align: center;
    }
  }

  .page-box {
    margin-bottom: 100px;
  }

  .onboard-btn-box {
    background-color: var(--ion-background-color);
    position: fixed;
    width: 100%;
    bottom: 0;
    padding-bottom: m#{a}x(10px, env(safe-area-inset-bottom));
    text-align: center;

    .onboard_big_button {
      // width: calc(100% - 2 * var(--base-padding));
      // TODO: unify below
      color: var(--color-purple-50);
      font-size: 16px;
      font-weight: 700;
      line-height: 140%; /* 22.4px */
      margin: var(--small-padding) var(--base-padding);

      &::part(native) {
        padding: var(--semi-padding) var(--mid-padding);
      }
    }
  }
}
