.filters {
  .content {
    padding: 5px 10px 10px 10px;

    ion-button {
      margin: 0 10px 10px 0;

      &::part(native) {
        border: 1px solid var(--ion-color-primary);
      }
    }

    ion-label {
      font-size: 12px;
      color: var(--text-light);
      margin-left: 10px;
    }

    ion-range {
      padding-bottom: 0px;
    }
  }
}

ion-popover {
  ion-header {
    &.popover-qf-header {
      color: #8c9295;
    }

    ion-title {
      padding: 16px 10px 8px 10px;
    }
  }

  ion-list {
    padding-bottom: 10px;

    ion-item {
      &::part(native) {
        padding-right: 8px;
      }
    }
  }

  &.popover_quick_filters {
    --backdrop-opacity: 0.6;
    --box-shadow: rgba(0, 0, 0, 0.6);
    --color: white;
    --width: 98%;

    &.qf-search-map {
      --offset-y: 10%;
    }

    &.high {
      --height: 70%;
    }

    ion-checkbox {
      &.qf-checkbox {
        width: 31px;
        height: 31px;
        margin-right: 0px;
      }

      &::part(container) {
        border-radius: 6px;
        border: 2px solid var(--ion-color-primary);
      }
    }
  }
}
