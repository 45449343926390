.loading,
.no-internet {
  &::part(background) {
    background-image: url("../css_assets/logo.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 30%;
  }
}

.no-internet {
  position: relative;
  &::part(background) {
    background-position: center 40%;
  }
  p {
    padding: 1rem 3rem;
    text-align: center;
    line-height: 1.4rem;
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
  }
}

.header-ios ion-toolbar:last-of-type {
  --border-width: 0px;
}

ion-header {
  ion-navbar.toolbar.toolbar-ios.statusbar-padding,
  ion-navbar.toolbar-ios ion-title.title-ios,
  ion-toolbar.toolbar.toolbar-ios.statusbar-padding,
  ion-toolbar.toolbar-ios ion-title.title-ios,
  ion-toolbar.toolbar-title-default.ios {
    // this handles notch on some phones
    // -15px is subtracted, if the default value was too high, tested on iPhone XR..
    //padding-top: m#{a}x(calc(env(safe-area-inset-top) - 15px), 0px);
    padding-top: calc(env(safe-area-inset-top) * 0.7);
    min-height: calc(env(safe-area-inset-top) * 0.7 + 60px);
    padding-left: 0;
    padding-right: 0;

    ion-title {
      min-height: calc(60px);
      color: var(--blue-grey-blue-grey-900);
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: 0.3px;
    }
  }

  ion-toolbar {
    --background: white;
    --border-width: 0px;
    --padding-bottom: 0;
    --padding-end: var(--base-padding);
    --padding-start: var(--base-padding);
    --padding-top: 0;

    ion-buttons {
      ion-button,
      ion-back-button {
        // padding-top: 5px;
        // padding-bottom: 5px;
        margin: 0;
        --padding-bottom: 0;
        --padding-end: 0;
        --padding-start: 0;
        --padding-top: 0;
      }
    }
  }

  .sc-ion-buttons-ios-s ion-button {
    height: 52px;
    margin: 0;
    --padding-bottom: 0;
    --padding-end: 0;
    --padding-start: 0;
    --padding-top: 0;
  }
}

.searchbar-has-value .searchbar-clear-button {
  display: block;
  border-radius: var(--border-radius-small);
  margin-top: 5px;
  margin-right: 5px;
  background-color: var(--ion-color-step-600);
  color: white;
  height: 26px;
  width: 26px;
}

.plt-mobileweb body,
.plt-desktop body {
  background-color: #f9f6fa;

  div.ion-page {
    background-color: white;
    max-width: 850px;
    //max-height: 1000px;
    margin: auto;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.03);
  }

  div.ion-tabs {
    width: 500px;
  }
}

:not(ion-header) > ion-toolbar {
  --background: white;
}

ion-tab-bar {
  height: 73px;
  --background: white;
  --border: none;

  ion-tab-button {
    padding-top: 11px;
    padding-bottom: 13px;
    position: relative;
    color: var(--blue-grey-blue-grey-300);
    font-family: PPTelegraf;
    font-size: 11px;
    font-weight: 500;
    line-height: 140%; /* 15.4px */
    letter-spacing: 0.3px;

    &.tab-selected {
      color: var(--color-purple-500);

      // &::before {
      //   content: "";
      //   border-top: 4px solid var(--color-purple-500);
      //   width: 60%;
      //   height: 2px;
      //   display: block;
      //   position: absolute;
      //   top: 0px;
      //   left: 20%;
      //   margin: auto;
      // }

      ion-icon {
        color: var(--color-purple-500);
      }
    }

    ion-icon {
      width: 24px;
      height: 24px;
      padding-bottom: 3px;
      color: var(--blue-grey-blue-grey-200);
    }

    .notification_dot {
      position: absolute;
      top: 5px;
      right: calc(50% - 25px);
    }
  }
}

.padding-base {
  --padding-start: var(--base-padding, 10px);
  --padding-end: var(--base-padding, 10px);
  --padding-top: var(--base-padding, 10px);
  --padding-bottom: var(--base-padding, 10px);
}

.padding-base_notch-top {
  --padding-top: m#{a}x(var(--base-padding), env(safe-area-inset-top));
}

ion-modal {
  ion-button.modal_big_button {
    margin-bottom: m#{a}x(10px, env(safe-area-inset-bottom));
  }
}

ion-card {
  border-radius: 0px;
}

ion-popover ion-list {
  padding-bottom: 0;

  h4 {
    margin-left: 10px;
  }
}

.separator {
  width: 100%;
  margin: 16px 0 0 0;
  border-bottom: 5px solid var(--border-light);
}

ion-segment:not(.original) {
  border-radius: 0px;
  background: white;
  margin-top: 0;
  -webkit-box-shadow: 0 4px 2px -2px rgb(192, 192, 192);
  box-shadow: 0 0px 1px 1px rgba(192, 192, 192, 0.2);

  ion-segment-button {
    border-bottom: 2px solid var(--border-light);
    border-radius: 0;
    margin-bottom: 0;
    min-height: 42px;
  }

  ion-segment-button.segment-button-checked {
    border-bottom: 2px solid var(--ion-color-primary);
    border-radius: 0;

    &::part(indicator-background) {
      border-radius: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
    }
  }

  &.form_segment {
    -webkit-box-shadow: 0 0 0 0 rgb(192, 192, 192);
    box-shadow: 0 0 0 0 rgba(192, 192, 192, 0);
    ion-segment-button {
      border-bottom: 0px;

      &.segment-button-checked {
        border-bottom: 2px solid var(--ion-color-primary);
      }
    }
  }
}

ion-segment.original {
  margin-top: 0;

  &.ion-color-tertiary {
    background: var(--ion-color-primary-light-5-alpha);
  }

  &.simple {
    height: 44px;
    ion-segment-button {
      min-width: auto;
    }
  }
}

ion-segment.spacing {
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 20px;
  width: calc(100% - 20px);
}

// .absolute_toolbar {
//   position: absolute;
//   top: 3px;
//   right: 4px;
// }

ion-title img {
  height: 44px;
  margin-top: 4px;
  margin-bottom: -4px;
}

.logo {
  width: 80px;
  height: 56px;
  margin: auto;
  margin-top: 15px;
  display: block;
}

.align_right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.stores {
  position: absolute;
  bottom: 0px;
  height: 73px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .store {
    z-index: 10000;
    --padding-start: 10px;
    --padding-end: 10px;

    img {
      width: 100%;
      max-height: 100%;
    }
  }
}

.content-padded {
  padding: 0 var(--base-padding) var(--base-padding) var(--base-padding);
}

.page-loading {
  position: absolute;
  z-index: 100000;
}

.container {
  padding: 0 var(--base-padding) 0 var(--base-padding);
}

ion-grid,
ion-col,
ion-row {
  margin: 0;
  // padding: 0;
}
