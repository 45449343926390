.page-guidepost {
  ion-content {
    --background: url("../css_assets/wallpapers/guidepost.png") no-repeat center -30px;
  }

  .guidepost-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .guidepost-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding-top: calc(var(--base-padding) + env(safe-area-inset-top));
    padding-right: var(--base-padding);
  }

  .button-skip {
    height: 28px;
    color: var(--blue-grey-blue-grey-900);
    font-size: 14px;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.2px;
    // right: var(--base-padding);
    width: auto;
    // position: absolute;
    // top: calc(var(--base-padding) + env(safe-area-inset-top));

    &::part(native) {
      padding: 4px 13px;
      background-color: #fff;
    }
  }

  // .container {
  //   padding-top: 300px;
  // }

  h1 {
    text-align: center;
    margin-bottom: var(--space-l);
  }

  .buttons {
    text-align: center;

    ion-button {
      margin-bottom: var(--space-l);
    }
  }

  .button-small {
    text-align: center;
    padding: 20px;
    color: var(--blue-grey-blue-grey-900);
    font-size: 16px;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.2px;
  }
}
