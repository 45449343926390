.profile_photo {
  width: 120px;
  height: 120px;
  display: inline-block;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  border-radius: 60px;
  position: relative;
  margin-top: 10px;
  // -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
  // box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);

  ion-icon {
    position: absolute;
    bottom: -5px;
    right: -10px;
  }
}

.page-profile {
  .profile-title {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  // .profile_section_header {
  //   margin-left: 10px;
  //   margin-bottom: 10px;
  //   margin-top: 25px;
  //   color: var(--ion-color-dark);
  //   font-size: 15px;
  // }

  // .profile_section_link {
  //   // margin: 5px 0;
  //   // font-size: 18px;
  //   // line-height: 180%;
  //   // display: block;
  //   // text-decoration: none;

  //   // a {
  //   //   text-decoration: none;
  //   // }

  //   ion-icon {
  //     vertical-align: -5%;
  //     &.share-icon-profile-section {
  //       vertical-align: -3%;
  //     }
  //   }
  // }

  // .profile_section {
  //   margin-bottom: 24px;

  //   h3 {
  //     margin-bottom: 0;
  //   }
  //   // padding: 5px 10px;
  //   // margin: 5px 10px;
  //   // background-color: var(--ion-color-primary-light-10-alpha);
  //   // border-radius: var(--border-radius-small);

  //   // ion-grid {
  //   //   padding: 0;
  //   // }

  //   // .profile_section_link_important a {
  //   //   color: var(--ion-color-primary-red);
  //   // }
  // }

  .profile_section_light {
    margin: 50px 0;

    .profile_section_link {
      text-align: center;
      color: var(--blue-grey-blue-grey-500);
      font-size: 10px;
      font-weight: 500;
      line-height: 160%; /* 16px */
      letter-spacing: 0.3px;
    }
  }
}

.profile-menu {
  .menu-button {
    cursor: pointer;
  }
}

.interest {
  span {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: white;
    font-weight: bold;
    font-size: large;
    text-shadow: 1px 1px 2px #000;
  }

  ion-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    width: 30px;
    height: 30px;
  }
}

// .profile_stats {
//   p {
//     margin: 7px 0;
//   }

//   ion-col:last-of-type {
//     text-align: right;
//   }
// }

.profile_row {
  // position: relative;
  margin-bottom: 24px;

  h3 {
    margin-top: 0;
    margin-bottom: 0;
  }

  span {
    color: var(--blue-grey-blue-grey-900);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.3px;
  }

  .profile_row_first {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ion-toggle {
      // margin-top: var(--space-m);
      // height: 24px;
      
    }
  }

  .warn_text {
    color: var(--blue-grey-blue-grey-700);
    font-size: 12px;
    font-weight: 400;
    line-height: 150%; /* 18px */
    letter-spacing: 0.4px;
  }

  ion-button {
    ion-icon {
      width: 30px;
      height: 30px;
    }
  }
}

.delete_account_modal {
  .warn_text {
    font-size: 12px;
    color: #eb445a;
  }
}

.profile-edit-form {
  margin-bottom: 30px;

  input {
    margin-top: var(--space-2xl);
  }
}