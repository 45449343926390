.results_section {
  text-align: center;
  margin-top: 15px;
  font-size: 14px;
  font-weight: bold;
  color: rgba($color: #000000, $alpha: 0.8);
}

.no_results {
  color: #872222;
  text-align: center;
}

.use_special {
  em {
    color: var(--ion-color-primary-shade);
  }

  p {
    margin: 0px;
  }
}

.time_picker_main_btn {
  &::part(native) {
    background-color: white;
    margin: 0px;
    padding: 0px;
    line-height: 1.5;
  }
}

ion-modal#time-picker-modal {
  --box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: 0.36;
  --border-radius: var(--border-radius-small);
  ion-datetime.time_picker {
    min-width: 200px;
    --ion-color-step-150: var(--ion-color-primary-light);
    box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);

    .time_picker_btns {
      color: var(--ion-color-primary);

      .time_picker_btn {
        background-color: var(--ion-color-primary-light);
        border-radius: var(--border-radius-small);
      }
    }
  }
}

ion-modal#calendar-modal {
  --max-width: 350px;
  --height: fit-content;
  --border-radius: var(--border-radius-small);
  --box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: 0.36;

  .calendar-datepicker {
    margin-top: 10px;
  }

  ion-button {
    height: 42px;
    font-size: 15px;
  }
}

.dates-box {
  margin-left: 10%;
  width: 80%;
  display: flex;
  flex-direction: column;

  .date-day {
    font-weight: 600;
    text-align: center;
  }
  .date-year {
    color: #a2a2a2;
    text-align: end;
  }
  ion-button {
    margin-top: 10px;
  }
}

.time-picker-btns-box {
  display: flex;
  justify-content: space-around;
}

.event-public-switch {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;

  .item {
    width: 200px;
    text-align: center;
    border-radius: var(--border-radius-small);
    padding: 10px 10px;
    margin: 10px 0;
    box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    position: relative;

    &.disabled {
      background: rgba(0, 0, 0, 0.1);
    }

    ion-badge {
      position: absolute;
      top: -5px;
      right: -10px;
      opacity: 0.8;
    }

    ion-icon {
      width: 24px;
      height: 24px;
      color: #666;
    }
  }
}
