.page-intro {
  .welcome {
    position: absolute;
    left: var(--space-xl);
    z-index: 2;
    top: calc(var(--space-s) + env(safe-area-inset-bottom) + var(--space-m));
    color: var(--lighter-80);
    font-size: 14px;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    gap: var(--space-s);

    ion-icon {
      width: 38.5px;
      height: 26.6px;
    }
  }

  .fullslides {
    height: 100%;

    .swiper-pagination {
      position: absolute;
      top: calc(var(--space-s) + env(safe-area-inset-bottom));
      // background-color: red;
      z-index: 2;
      width: 100%;
      padding-left: var(--space-m);
      padding-right: var(--space-m);
      display: flex;
      justify-content: center;
      flex-direction: row;
      gap: var(--space-s);

      .swiper-pagination-bullet {
        border-radius: 100px;
        max-width: 50px;
        width: 50px;
        height: 3px;
        background: var(--lighter-30);
        display: block;

        &.swiper-pagination-bullet-active {
          background: white;
        }
      }
    }

    .swiper-slide {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &.slide-category-1 {
        background: radial-gradient(
            circle,
            rgba(0, 0, 0, 0) 20%,
            rgba(0, 0, 0, 0.5) 65%
          ),
          url("../css_assets/slides/1.jpg") top center / cover;
      }

      &.slide-category-2 {
        background: radial-gradient(
            circle,
            rgba(0, 0, 0, 0) 20%,
            rgba(0, 0, 0, 0.5) 65%
          ),
          url("../css_assets/slides/2.jpg") top center / cover;
      }

      &.slide-category-3 {
        background: radial-gradient(
            circle,
            rgba(0, 0, 0, 0) 20%,
            rgba(0, 0, 0, 0.5) 65%
          ),
          url("../css_assets/slides/3.jpg") top center / cover;
      }

      &.slide-category-4 {
        background: radial-gradient(
            circle,
            rgba(0, 0, 0, 0) 20%,
            rgba(0, 0, 0, 0.5) 65%
          ),
          url("../css_assets/slides/4.jpg") top center / cover;
      }

      .main {
        padding-right: 50px;
        padding-bottom: calc(110px + env(safe-area-inset-bottom));
        // background: linear-gradient(
        //   180deg,
        //   rgba(58, 58, 58, 0) 0%,
        //   rgba(0, 0, 0, 0.42) 21.35%,
        //   #0f0f0f 53.65%
        // );
      }

      .label {
        text-align: left;
        color: var(--lighter-60);
        font-size: 12.5px;
        font-weight: 500;
        line-height: 120%; /* 15px */
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      h1 {
        color: var(--colors-grey-grey-000, #fff);
        font-size: 44px;
        font-weight: 500;
        line-height: 110%; /* 48.4px */
        letter-spacing: 0.3px;
      }

      .text {
        text-align: left;
        color: var(
          --colors-transparent-lighter-lighter-80,
          rgba(255, 255, 255, 0.8)
        );
        font-size: 14px;
        font-weight: 500;
        line-height: 140%; /* 19.6px */
        letter-spacing: 0.3px;
      }
    }
  }

  .actions {
    position: absolute;
    bottom: calc(50px + env(safe-area-inset-bottom));
    height: 50px;
    z-index: 2;
    width: 100%;
    text-align: center;
  }
}
