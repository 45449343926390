.modal-cropper {
  ::part(background) {
    background-color: black;
  }
  .reactEasyCrop_Container {
    background-color: black;
  }

  .cropper-content {
    position: relative;
    height: 100%;
    height: calc(100% - 70px);
  }

  .button-use,
  .button-cancel {
    position: absolute;
    bottom: 0px;
    height: 70px;
    margin: 0 20px 0 20px;

    &::part(native) {
      background-color: black;
    }
  }

  .button-use {
    right: 0px;
  }

  .button-cancel {
    left: 0px;
  }
}
