.benefits-modal {
  .label {
    text-align: center;
    font-size: 25px;
    margin: 15px;
  }
  .benefits-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    ion-item {
      font-size: 18px;
    }
  }
}
.action-btns {
  display: flex;
  flex-direction: column;
  align-items: center;

  ion-button {
    font-size: 20px;
    margin-bottom: 10px;
  }
}

.disabled_component {
  font-size: 17px;
  margin: 0px 25px 100px 25px;
  display: flex;
  align-items: center;
  flex-direction: column;

  .potato {
    margin-top: 30px;
    margin-bottom: 20px;
    height: 160px;
    max-width: 60%;
    display: block;

    &.potato-friends {
      max-width: 70%;
    }
    &.potato-profile {
      max-width: 70%;
    }
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
  }

  ul {
    margin-top: 5px;
    margin-bottom: 25px;
    padding-left: 0px;
    list-style-type: none;

    li {
      margin-top: 14px;
      margin-bottom: 14px;
    }
  }

  @media only screen and (max-height: 800px) {
    margin: 0px 25px 0px 25px;

    .potato {
      margin-top: 10px;
      margin-bottom: 0px;
    }

    h3 {
      margin-top: 15px;
      margin-bottom: 15px;
    }

    ul {
      margin-top: 0px;
      margin-bottom: 15px;
    }
  }

  @media only screen and (max-height: 690px) {
    margin: 0px 25px 0px 25px;

    .potato {
      margin-top: 10px;
      margin-bottom: 0px;
    }

    h3 {
      margin-top: 10px;
      margin-bottom: 5px;
    }

    ul {
      margin-top: 0px;
      margin-bottom: 5px;
    }

    ion-button {
      margin-bottom: 6px;
    }
  }
}
