/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /*
  * Fonts
  */

  @font-face {
    font-family: "PPTelegraf";
    font-weight: normal;
    // src: url('/font-face/Regular/Roboto-Regular.eot'); /* IE9 Compat Modes */
    src: // url('/font-face/Regular/Roboto-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url("../css_assets/fonts/pptelegraf/web/PPTelegraf-Regular.woff")
        format("woff"),
      /* Modern Browsers */
        url("../css_assets/fonts/pptelegraf/ttf/PPTelegraf-Regular.ttf")
        format("truetype"); /* Safari, Android, iOS */
    //  url('/font-face/Regular/Roboto-Regularo.svg#svgFontName') format('svg'); /* Legacy iOS */
  }

  @font-face {
    font-family: "PPTelegraf";
    font-weight: 500;
    src: url("../css_assets/fonts/pptelegraf/web/PPTelegraf-Medium.woff")
        format("woff"),
      url("../css_assets/fonts/pptelegraf/ttf/PPTelegraf-Medium.ttf")
        format("truetype");
  }

  @font-face {
    font-family: "PPTelegraf";
    font-weight: 600;
    src: url("../css_assets/fonts/pptelegraf/web/PPTelegraf-Semibold.woff")
        format("woff"),
      url("../css_assets/fonts/pptelegraf/ttf/PPTelegraf-Semibold.ttf")
        format("truetype");
  }

  @font-face {
    font-family: "PPTelegraf";
    font-weight: bold;
    src: url("../css_assets/fonts/pptelegraf/web/PPTelegraf-Bold.woff")
        format("woff"),
      url("../css_assets/fonts/pptelegraf/ttf/PPTelegraf-Bold.ttf")
        format("truetype");
  }

  ion-content {
    font-family: PPTelegraf;
  }

  --ion-font-family: PPTelegraf;

  --ion-background-color: #fff;
  --ion-background-color-rgb: 255, 255, 255;

  --ion-background-color-light: #f0f0f0;

  --ion-text-color: #454545;
  --ion-text-color-lighter: #737373;

  /** primary **/
  // --ion-color-primary: #8a2ab5; // dark purple from logo made lighter
  --ion-color-primary-red: #ad2483; // red from logo
  --ion-color-primary-text: #8a2ab5;
  //--ion-color-primary: #8400ff; OLD PURPLE COLOR
  --ion-color-primary-rgb: 138, 42, 181;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #5a2483; // dark purple from logo
  --ion-color-primary-tint: #8c36b4; // primary color (#8a2ab5) made little bit lighter by eye

  --ion-color-primary-light: #efdff4; // 15% alpha to primary calcuated to non-alfa
  --ion-color-primary-light-15-alpha: #8a2ab526; // 15% alpha to primary
  --ion-color-primary-light-10-alpha: #8a2ab51a; // 10% alpha to primary
  --ion-color-primary-light-5-alpha: #8a2ab50d; // 5% alpha to primary

  // red -> purple from logo gradient
  --ion-color-primary-gradient: linear-gradient(
    145deg,
    #ad2483 0%,
    #5a2483 100%
  );
  // --ion-color-primary-gradient-light: linear-gradient(
  //   145deg,
  //   #ad2483 0%,
  //   #8a2ab5 100%
  // );
  --ion-color-primary-gradient-light: var(--ion-color-primary-text);

  /** secondary **/
  --ion-color-secondary: #c592da; // 50% alpha from primary => non alpha
  --ion-color-secondary-rgb: 197, 146, 218;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #b577d0; // 65% alpha from primary => non alpha
  --ion-color-secondary-tint: #d8b6e6; // 35% alpha from primary => non alpha

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #24ad6b; // custom
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #1c8c56; // custom
  --ion-color-success-tint: #42d77d;

  --ion-color-success-gradient: linear-gradient(
    145deg,
    #24ad6b 0%,
    #167547 100%
  );

  /** warning **/
  --ion-color-warning: #fcbe47; // custom orange
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #ef7f08; // custom orange
  --ion-color-warning-tint: #ffca22;

  --ion-color-warning-gradient: linear-gradient(
    145deg,
    #fcbe47 0%,
    #ef7f08 100%
  );

  --ion-color-warning-background: #f9ebd1;

  /** danger **/
  --ion-color-danger: #ad2424; // red from logo
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #ad2466; // red from logo made more red
  --ion-color-danger-tint: #ed576b;

  // red from logo made more red -> adjusted red from logo
  --ion-color-danger-gradient: linear-gradient(
    145deg,
    #ad2424 0%,
    #ad2466 100%
  );

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-dark-light: #dededf; // 15% alfa on --ion-color-dark

  /** medium **/
  --ion-color-medium: #92949c; // custom grey
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #6e6f75; // custom grey
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-medium-gradient: linear-gradient(
    145deg,
    #92949c 0%,
    #6e6f75 100%
  );

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --border-radius-small: 8px;
  --border-radius-mid: 12px;
  --border-radius-bigger: 16px;
  --border-radius-big: 20px;
  --text-light: #616161;
  --border-light: #eef1f4;
  --border-grey: #cdcdcd;
  --border-grey-rgb: 205, 205, 205;
  --safe-area-inset-top-reduced: calc(env(safe-area-inset-top) * 0.7 - 15px);

  --ion-color-step-600: #666666;

  --shadow-slight: 0px 2px 2px rgba(33, 36, 41, 0.1);

  --base-padding: 20px;
  --small-padding: 10px;
  --mid-padding: 20px;
  --semi-padding: 18.5px;
  --padding-big: 36px;
  --padding-huge: 36px;
  --items-spacing-small: 8px;
  --items-spacing-padding: 16px;

  --color-purple-900: rgba(44, 10, 70, 1);
  --color-purple-800: rgba(54, 12, 85, 1);
  --color-purple-700: rgba(102, 26, 154, 1);
  --color-purple-600: rgba(127, 34, 186, 1);
  --color-purple-500: rgba(158, 53, 229, 1); // #9E35E5
  --color-purple-400: rgba(195, 123, 246, 1);
  --color-purple-300: rgba(222, 177, 250, 1);
  --color-purple-200: rgba(237, 214, 252, 1);
  --color-purple-100: rgba(251, 243, 254, 1);
  --color-purple-50: #FBF3FE;
  --color-purple-25: #FEFBFF;

  --ion-color-primary: var(--color-purple-500);

  --color-pink: rgba(225, 53, 229, 1) // #E135E5;
  // complementray/pink-500 // rgba(225, 53, 229, 1) // #E135E5

  --blue-grey-blue-grey-999: #000000;
  --blue-grey-blue-grey-900: #141518;
  --blue-grey-blue-grey-800: #22242A;
  --blue-grey-blue-grey-700: #33363F;
  --blue-grey-blue-grey-600: #4A4D5A;
  --blue-grey-blue-grey-500: #636878;
  --blue-grey-blue-grey-400: #7e8395;
  --blue-grey-blue-grey-300: #999DAC;
  --blue-grey-blue-grey-200: #C0C3CC;
  --blue-grey-blue-grey-100: #DDDEE3;
  --blue-grey-blue-grey-050: #F3F3F5;
  --blue-grey-blue-grey-025: #FAFAFB;
  --blue-grey-blue-grey-000: #FFF;


  --lighter-100:	#FFFFFFFF;
  --lighter-95:	#FFFFFFF2;
  --lighter-90:	#FFFFFFE6;
  --lighter-85:	#FFFFFFD9;
  --lighter-80:	#FFFFFFCC;
  --lighter-75:	#FFFFFFBF;
  --lighter-70:	#FFFFFFB3;
  --lighter-65:	#FFFFFFA6;
  --lighter-60:	#FFFFFF99;
  --lighter-55:	#FFFFFF8C;
  --lighter-50:	#FFFFFF80;
  --lighter-45:	#FFFFFF73;
  --lighter-40:	#FFFFFF66;
  --lighter-35:	#FFFFFF59;
  --lighter-30:	#FFFFFF4D;
  --lighter-25:	#FFFFFF40;
  --lighter-20:	#FFFFFF33;
  --lighter-15:	#FFFFFF26;
  --lighter-10:	#FFFFFF1A;
  --lighter-05:	#FFFFFF0D;
  --lighter-00:	#FFFFFF00;

  --color-test-black: #1a0529;

  /* DETAIL */
  --detail-img-ratio: 10/7;

  --space-2xs: 4px;
  --space-xs: 8px;
  --space-s: 12px;
  --space-m: 16px;
  --space-l: 20px;
  --space-xl: 24px;
  --space-2xl: 32px;
  --space-3xl: 40px;
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */
  body.xx {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0, 0, 0;

    --ion-color-primary-text: #f26fcb;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255, 255, 255;

    --ion-color-step-600: #999999;

    --ion-toolbar-background: #0d0d0d;

    --border-grey: #29013a;
  }
}

/*  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

/*.ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-toolbar-background: #0d0d0d;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

/*.md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}*/
