.btn-social {
  text-align: center;
  // width: calc(100% - 2px);
  width: 100%;
  max-width: 270px;
  height: 50px;

  &::part(native) {
    color: var(--blue-grey-blue-grey-900);
    --border-radius: 100px;
    border: 1px solid var(--button-register-button-register-outline, #dddee3);
    font-size: 16px;
    font-weight: 600;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.2px;
  }

  &.btn-social-primary::part(native) {
    color: var(--color-purple-25);
  }

  img {
    height: calc(50px - 28px);
    padding: 3px 10px 3px 0;
  }

  // &.btn-google {
  //   color: #4285f4;

  //   // &::part(native) {
  //   //   border: 1px solid #4285f4;
  //   // }
  // }

  // &.btn-apple {
  //   color: black;

  //   // &::part(native) {
  //   //   border: 1px solid black;
  //   // }
  // }
}

// NOTE: Apple buttons are only on ios => 2 buttons in a row => text has to be more compact
@media only screen and (max-width: 440px) {
  .plt-ios .btn-social {
    img {
      padding-right: 4px;
    }
  }
}

@media only screen and (max-width: 400px) {
  .plt-ios .btn-social {
    font-size: 14px;
  }
}

@media only screen and (max-width: 360px) {
  .plt-ios .btn-social {
    font-size: 12px;
  }
}
