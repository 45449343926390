.empty_section_solid {
  font-size: 18px;
  text-align: center;
  margin: 130px 25px 100px 25px;
  display: flex;
  align-items: center;
  flex-direction: column;

  ion-icon {
    padding-right: 0;
    margin-bottom: 10px;
  }

  ion-button {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;

    ion-icon {
      margin-right: 10px;
      margin-bottom: 2px;
    }

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }

    &::part(native) {
      padding-right: 15px;
    }
  }

  em {
    color: var(--ion-color-primary-shade);
  }

  .empty_section_icon {
    width: 50px;
    height: 50px;
  }

  p,
  h3 {
    margin: 0;
    margin-bottom: 15px;
    font-size: 16px;
  }

  h3 {
    font-size: 18px;
  }
}
