.event_detail_wrapper {
  position: relative;
  z-index: 2;
}

.event_details {
  position: relative;
  margin-top: 0px;
  --margin-top: 0px;
  background: white;

  .icon-bookmark {
    width: 40px;
    height: 40px;
    position: relative;
    top: 4px;
  }

  .visual_container {
    width: 100%;
    margin-bottom: -40px;
    position: relative;
    aspect-ratio: var(--detail-img-ratio);

    .visual_content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      overflow: hidden;

      .gradient {
        height: 107px;
        flex-shrink: 0;
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.01) 6.67%,
          rgba(255, 255, 255, 0.04) 13.33%,
          rgba(255, 255, 255, 0.08) 20%,
          rgba(255, 255, 255, 0.15) 26.67%,
          rgba(255, 255, 255, 0.23) 33.33%,
          rgba(255, 255, 255, 0.33) 40%,
          rgba(255, 255, 255, 0.44) 46.67%,
          rgba(255, 255, 255, 0.56) 53.33%,
          rgba(255, 255, 255, 0.67) 60%,
          rgba(255, 255, 255, 0.77) 66.67%,
          rgba(255, 255, 255, 0.85) 73.33%,
          rgba(255, 255, 255, 0.92) 80%,
          rgba(255, 255, 255, 0.96) 86.67%,
          rgba(255, 255, 255, 0.99) 93.33%,
          #fff 100%
        );
        position: absolute;
        bottom: 0px;
        width: 100%;
      }

      .map-ev_det {
        display: inline-block;
        height: 100%;
        width: 100%;
      }

      // img {
      width: 100%;
      aspect-ratio: var(--detail-img-ratio);
      background-position: center top;
      background-repeat: no-repeat;
      background-size: cover;
      // }
    }
  }

  .tags {
    margin-top: var(--padding-big);
  }

  .header-menu-floating {
    width: 100%;
    padding: var(--base-padding);
    padding-top: calc(var(--base-padding) + env(safe-area-inset-top));
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;

    .button {
      width: 40px;
      height: 40px;
      padding: 0px;
      --color: black;
      position: relative;
      z-index: 1;

      &::part(native) {
        z-index: 1;
        background-color: white;
        border-radius: var(--border-radius-big);
        color: black;
        box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.12);
        padding: 8px;
      }

      &::part(icon) {
        width: 24px;
        height: 24px;
        margin: 0px;
      }
    }

    ion-icon {
      padding: 0;
      width: 24px;
      height: 24px;
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  ion-grid {
    padding-left: 0;
    padding-right: 0;
  }

  ion-badge {
    position: absolute;
    top: 0px;
    right: -5px;
    padding-right: 2px;
    padding-top: 1px;
    border-radius: 0px;
    border-bottom-left-radius: 8px;
  }

  .event_info_row {
    display: flex;
    justify-content: space-between;
  }

  table {
    // margin: 5px 10px 0px 10px;
    margin-top: var(--space-l);

    tr {
      td {
        padding: 5px 0 0 0;

        &:first-of-type {
          width: 100%;
        }
      }
    }
  }

  .event_info_nice {
    display: flex;
    min-height: 50px;
    border-radius: var(--border-radius-small);
    margin-top: 26px;
    justify-content: space-between;

    // .icon {
    //   .calendar_icon_inner {
    //     top: 21px;
    //     position: absolute;
    //     overflow: visible;
    //   }
    //   position: relative;
    //   width: 40px;
    //   height: 50px;
    //   margin: 0px 10px 0 0;
    //   flex-shrink: 0;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   // border-radius: var(--border-radius-small);
    //   // background-color: var(--ion-color-primary-light);

    //   .texts {
    //     margin: 5px 0 0 0;
    //   }

    //   ion-icon {
    //     // width: 34px;
    //     // height: 34px;
    //     width: 40px;
    //     height: 40px;
    //     padding-right: 0px;
    //   }
    // }

    // .icon_box ion-icon {
    //   position: relative;
    //   top: 1px;
    // }

    &.event_info_nice_compact {
      // height: 30px;

      // .texts {
      //   margin: 10px 0;
      //   height: 30px;
      // }

      // .icon {
      //   margin: 10px 5px 10px 0;
      //   width: 30px;
      //   height: 30px;
      //   background-color: transparent;

      //   ion-icon {
      //     width: 25px;
      //     height: 25px;
      //   }
      // }
    }

    // &.event_info_nice_link {
    //   color: var(--ion-color-primary);

    //   ion-icon {
    //     color: var(--ion-color-primary);
    //   }
    // }

    // &.invite_nice {
    //   height: 50px;
    // }

    .more {
      display: flex;
      align-items: center;

      ion-icon {
        width: 40px;
        height: 30px;
      }
    }

    .texts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: var(--blue-grey-blue-grey-900, #141518);

      .title {
        color: var(--blue-grey-blue-grey-500, #636878);
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.3px;
        margin-bottom: 5px;
      }

      .main {
        overflow: hidden;
        color: var(--blue-grey-blue-grey-900);
        font-size: 16px;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        letter-spacing: 0.2px;
      }

      .text {
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.3px;
      }
    }
  }

  .event_basic_info {
    padding-bottom: 10px;

    .share {
      margin-top: 16px;
      float: right;
      margin-right: 10px;
    }
  }

  .map {
    width: 100%;
    height: 100%;
  }

  .btn-map {
    position: absolute;
    right: 7px;
    top: 5px;
    height: 40px;
    z-index: 2;

    &::part(native) {
      box-shadow: var(--shadow-slight);
    }

    ion-icon {
      height: 25px;
      width: 25px;
      padding-right: 0;
    }
  }

  .event_detail_row {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 500px) {
      flex-direction: row;
      justify-content: space-between;
      gap: 50px;

      > div {
        width: 50%;
      }
    }
  }
}

ion-segment {
  margin-top: 10px;
}

.event_about {
  ion-icon {
    width: 25px;
    padding-right: 5px;
    vertical-align: middle;
    color: var(--text-light);
  }

  .info_row {
    font-size: 15px;
  }

  ion-button {
    ion-icon {
      color: inherit;
    }
  }
}

.btn-toolbar-inner {
  height: 40px;
  width: 48px;
  margin: 0;

  ion-icon {
    height: 30px;
    width: 30px;
    padding-right: 0;
  }
}

.event_voting {
  .finalize_voting {
    height: 36px;
  }

  .time {
    font-size: 15px;
    padding-top: 5px;

    span {
      vertical-align: -7%;
    }

    ion-icon {
      width: 20px;
      height: 20px;
      padding-right: 2px;
    }
  }

  .event_voting_option {
    display: flex;
    justify-content: space-between;
    min-height: 40px;
    padding: 0;
    width: calc(100% - 6px);
    border: 1px solid var(--border-grey);
    margin: 3px;
    font-size: 15px;
    border-radius: var(--border-radius-small);

    &.event_voting_option_voted {
      border: 1px solid var(--border-light);
    }

    &.event_voting_option_won {
      border: 1px solid var(--ion-color-primary);
    }

    .label,
    .info {
      display: flex;
      justify-content: space-between;
      padding: 5px 8px;
      span {
        padding-top: 2px;
        line-height: 1.4rem;
        margin-left: 33px;
      }
    }

    ion-checkbox {
      position: absolute;
    }

    .info {
      .votes {
        line-height: 26px;
        white-space: nowrap;
        padding-left: 10px;
      }

      ion-icon {
        width: 26px;
        height: 26px;
        padding: 0 0 0 5px;
      }
    }
  }
}

.voting_controls {
  text-align: center;
  color: var(--ion-color-primary);
  font-size: 14px;
}

.default_list {
  ion-checkbox {
    margin-top: 3px;
    margin-bottom: 20px;
    h2 {
      margin-left: 0;
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 2px;
      font-size: 17px;
      font-weight: normal;
    }
    .label_note {
      color: var(--text-light);
      font-size: 12px;
      margin-top: 5px;

      &.has_unreads {
        font-weight: bold;
        color: #555;
      }

      ion-icon {
        color: #888;
        vertical-align: -10%;
      }
    }
  }

  .votes_line {
    height: 12px;
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 97%;
  }
}

ion-segment.attendance {
  ion-segment-button {
    &::part(native) {
      @media only screen and (max-width: 430px) {
        padding-left: 3px;
        padding-right: 3px;
      }
      @media only screen and (max-width: 390px) {
        font-size: 12px;
      }
    }
  }
}
.spinner_ev_det {
  display: flex;
}
