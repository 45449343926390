.discussion {
  display: flex;
  flex-direction: column;

  .item {
    border-bottom: 1px dashed transparent;
    margin-bottom: 2px;

    &.message_last_seen {
      border-bottom: 1px dashed #ddd;
    }

    ion-label {
      text-overflow: clip;
      white-space: normal;
    }
  }

  .messages {
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 0px;

    ion-item {
      &:first-of-type {
        .message_text:last-child {
          margin-bottom: 15px;
        }
      }

      &::part(native) {
        border-bottom: 0px;
        padding-left: 10px;
      }
    }

    ion-avatar {
      width: 42px;
      height: 42px;
      margin-right: 10px;
      margin-top: 10px;
      margin-bottom: 10px;
      margin-bottom: auto;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    }

    ion-label {
      margin: 5px 5px 2px 0px;
    }

    h3 {
      padding-left: 3px;
      font-size: 12px;
    }

    .mine {
      text-align: right;
    }

    .their {
      .message_text {
        background-color: #eef1f4;
        margin-right: 20px;
      }
    }

    .mine {
      .message_text {
        background-color: var(--ion-color-primary-light-5-alpha);
        margin-left: 20px;
      }
    }

    .system {
      font-size: 12px;
      color: #666;
      text-align: center;

      .message_text {
        text-align: center;
      }
    }

    .time {
      color: #666;
      font-size: 10px;
      padding-left: 6px;
      padding-right: 3px;
    }

    .message_text {
      display: inline-block;
      border-radius: var(--border-radius-small);
      padding: 5px 9px;
      text-align: left;
      word-break: break-word;
      white-space: pre-wrap;
    }

    ion-item.message_same_user {
      ion-avatar {
        height: 20px;
        -webkit-box-shadow: none;
        box-shadow: none;
        img {
          visibility: hidden;
        }
      }

      &.message_same_time {
        --min-height: 30px;

        ion-label {
          margin-top: 0px;
        }

        h3 {
          display: none;
        }
      }

      .chat_seen_by {
        img {
          visibility: visible;
          height: 15px;
        }
      }
    }

    .chat_seen_by {
      font-size: 12px;
      max-height: 15px;
      font-style: oblique;

      ion-avatar {
        display: inline-block;
        width: 15px;
        height: 15px;
        margin: 0px;
      }

      ion-icon {
        width: 15px;
        height: 15px;
      }
    }

    .mine {
      .chat_seen_by {
        text-align: right;
      }
    }

    .chat_file {
      margin-right: 3px;
      display: inline-block;

      &::part(image) {
        height: 100px;
        width: auto;
        border-radius: var(--border-radius-small);
      }

      &.chat_file_single::part(image) {
        height: auto;
        width: auto;
        max-width: 200px;
        max-height: 200px;
      }
    }
  }
}

.discussion_form {
  border-top: 1px solid #c8c7cc;
  margin-top: -1px;
  background-color: white;

  ion-col {
    padding: 0;
  }

  ion-button {
    width: 100%;
    margin: 0;
  }

  .submit {
    position: absolute;
    bottom: -1px;
    padding: 0px 10px 0px 25px;
  }

  .select_photo {
    position: absolute;
    bottom: -1px;
    left: 0px;
    padding: 0px 10px 0px 5px;
    z-index: 3;
  }

  .select_images {
    position: absolute;
    bottom: -1px;
    left: 40px;
    padding: 0px 10px 0px 5px;
    z-index: 3;
    &.from_camera {
      left: 0px;
    }
  }

  ion-item {
    --inner-padding-end: 0px;
    --min-height: 28px;

    &::part(native) {
      padding: 0px;

      .item-inner {
        padding: 0px;
      }
    }
  }

  ion-input.discussion_input {
    --padding-top: 5px;
    --padding-end: 5px;
    --padding-bottom: 5px;
    --padding-start: 5px;
  }

  ion-textarea.discussion_input {
    --padding-top: 5px;
    --padding-end: 5px;
    --padding-bottom: 5px;
    --padding-start: 5px;
    max-height: 100px;
    min-height: 28px;

    .native-textarea {
      min-height: 28px;
    }

    &.discussion_input_empty {
      padding-left: 85px;
    }
  }

  .chat_file_to_upload {
    float: left;
    position: relative;

    img {
      height: 100px;
      border-radius: var(--border-radius-small);
      display: block;
      margin-right: 3px;
      margin-bottom: 3px;
    }

    ion-icon {
      position: absolute;
      top: 5px;
      right: 8px;
      width: 30px;
      height: 30px;
      padding: 5px;
      color: white;
      background-color: black;
      border-radius: var(--border-radius-big);
    }
  }
}

.slides_close,
.slides_download {
  position: absolute;
  right: 0px;
  z-index: 1000;

  img {
    height: 100px;
    border-radius: var(--border-radius-small);
    display: block;
  }

  ion-icon {
    width: 30px;
    height: 30px;
    padding: 5px;
    color: white;
    background-color: black;
    border-radius: var(--border-radius-big);
  }
}

.slides_close {
  top: 0px;
  padding: 30px 20px 50px 50px;
}

.slides_download {
  bottom: 0px;
  padding: 50px 20px 30px 50px;
}

.slides_toast {
  z-index: 1000;
  position: fixed;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  width: 200px;
  height: 200px;
  background: #ffffffaa;
  border-radius: var(--border-radius-big);
  text-align: center;

  ion-icon {
    width: 100px;
    height: 100px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  div {
    font-size: 24px;
  }
}

.button-link {
  color: var(--ion-color-primary, #3880ff);
  padding: 0;
  font-size: 1em;
  text-decoration: underline;
  background: transparent;
}
