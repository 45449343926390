.default_list.notifications_list {
  min-height: 100%;

  ion-item {
    font-size: 13px;
    height: 70px;
    padding: 0px;
    margin-top: 8px;
    margin-bottom: 8px;

    &.highlighted {
      .title {
        font-weight: bold;
      }

      &::part(native) {
        background-color: var(--ion-color-primary-light-5-alpha);
      }
    }
    &.seen {
      color: rgba($color: #000000, $alpha: 0.7);
    }

    .title {
      display: block;
      font-size: 14px;
    }

    .text {
      padding-top: 2px;
      display: block;
    }

    .time {
      display: block;
      padding-top: 3px;
      font-size: 10px;
      color: #888;
    }

    ion-avatar {
      padding: 0 15px 0 7px;
      width: 50px;
      margin-right: 10px;
    }
  }

  ion-label {
    margin: 5px 8px 5px 0;
    padding-top: 0px;
    height: 59px;
  }
}
