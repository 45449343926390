.header_error {
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  color: var(--ion-color-primary-shade);
}

.code_message {
  em {
    color: var(--ion-color-primary-shade);
    white-space: nowrap;
  }
}
