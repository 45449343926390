.header-announc {
  margin-top: 30px;
  line-height: 30px;
}

.survey_block {
  margin-bottom: 5px;

  .radio_sat_survey {
    display: flex;
    justify-content: space-between;
    ion-radio::part(container) {
      width: 40px;
      height: 40px;
    }
  }

  .levels_of_satisfaction {
    display: flex;
    justify-content: space-between;
    margin-top: -10px;
    padding: 5px;
    color: var(--ion-color-dark);
    font-size: 14px;
    font-style: italic;
  }
}

.announcement_btns {
  display: flex;
  justify-content: space-around;
  ion-button {
    width: 45%;
  }
}

span,
div {
  &.error {
    color: var(--ion-color-primary-red);
    font-size: 14px;
  }
}

// div.error {
//   border-top: 1px solid var(--ion-color-primary-red);
// }

.question {
  .survey_title {
    display: block;
    background-color: var(--ion-color-primary-light);
    line-height: 30px;
    font-size: 18px;
    font-weight: bold;
    margin: 0 -10px 0 -10px;
    padding: 10px;
  }
  .content_survey {
    margin: 30px -8px 30px -15px;

    ion-radio::part(container) {
      width: 40px;
      height: 40px;
      margin: 0 0 20px 0;
    }
    ion-checkbox {
      margin: 0 0 20px 0;
      &::part(container) {
        border-radius: 6px;
        border: 2px solid var(--ion-color-primary);
        height: 40px;
        width: 40px;
      }
    }
  }

  .item ion-label {
    white-space: normal;
  }
}
