.page-activities {
  h3 {
    font-size: 16px;
    margin-top: var(--space-3xl);
  }

  .activity {
    --padding-start: var(--base-padding);
    --padding-end: var(--base-padding);
    --padding-top: var(--space-xs);
    --padding-bottom: var(--space-xs);
    --inner-padding-end: var(--space-xs);
    --detail-icon-color: var(--blue-grey-blue-grey-200);
    --detail-icon-opacity: 1;
    color: var(--blue-grey-blue-grey-900);
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.3px;

    ion-avatar {
      width: 48px;
      height: 48px;
      padding: var(--space-s);
      border-radius: var(--border-radius-mid);
      background: var(--blue-grey-blue-grey-050);
      justify-content: center;
      align-items: center;
      margin-right: var(--base-padding);

      ion-icon {
        width: 24px;
        height: 24px;
        color: var(--blue-grey-blue-grey-300);
      }
    }
  }

  .empty_section_solid {
    margin-top: 50px;
  }
}
