.show_past_events {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: var(--text-light);

  ion-icon {
    color: var(--text-light);
    position: relative;
    top: 3px;
    margin-left: 5px;
  }
}

.past_events {
  ion-list {
    opacity: 0.7;
  }

  h4 {
    opacity: 0.6;
  }
}

ion-tabs {
  .stats {
    padding-left: 5px;
    color: #666;
    font-size: 11px;
    font-weight: bold;
  }
}

.events_list {
  &.ion-color-medium {
    opacity: 0.7;
  }
}
