.payment-form {
  .row-amount {
    display: flex;
    flex-direction: row;

    .amount-number {
      font-size: 26px;
    }
  }

  .row-description {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    padding-bottom: 10px;
  }
  .row-price {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 24px;
  }

  .button-changeNumber {
    width: 36px;
    height: 36px;
    margin: 0 10px;

    &::part(native) {
      padding: 0;
    }
  }

  .button-tickets{
    margin: 0 1rem 1rem 0
  }

  .item-column {
  padding: 0;
  display: flex;
  justify-content: center
}

}

ion-modal {
  --height: 95%

}
