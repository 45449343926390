.form-feedback {
  textarea.native-textarea {
    min-height: 200px;
    border: 1px solid #eee;
    padding: 5px 6px;
    border-radius: var(--border-radius-small);
  }

  .label {
    text-align: center;
    font-size: 18px;
    margin: 20px 0;
  }
}
