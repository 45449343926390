#map-view {
  // .search_controls_container {
  //   margin-top: calc(env(safe-area-inset-top) * 0.7);
  //   position: absolute;
  //   width: 100%;
  // }

  .map-canvas {
    position: absolute;

    height: 100%;
    width: 100%;

    background-color: transparent;

    opacity: 0;
    transition: opacity 250ms ease-in;
  }

  .show-map {
    opacity: 1;
  }

  .map_no-events {
    font-size: 20px;
    color: black;
    position: absolute;
    top: 150px;
    left: 0;
    width: 100%;
    padding: 11px 15px 15px 15px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    background: rgba(255, 255, 255, 0.6);
    background-image: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.15) 0%,
      rgba(0, 0, 0, 0.08) 50%,
      rgba(0, 0, 0, 0.04) 70%,
      rgba(0, 0, 0, 0.015) 100%
    );
    backdrop-filter: blur(3px);

    .no-events-second-row {
      margin-top: 5px;
      font-size: 16px;
    }
  }
}

ion-progress-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.search-controls {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 1;
  padding-left: var(--base-padding);
  padding-right: var(--base-padding);
  margin-top: env(safe-area-inset-top);

  .search-controls-row {
    // overflow-x: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: var(--space-xs);
  }
}

.map-icon {
  padding: var(--space-xs);
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-bigger);
  background: white;
  box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.12);
  color: var(--blue-grey-blue-grey-900);
  // position: absolute;
  // z-index: 2;

  // &.top-left {
  //   top: var(--base-padding);
  //   left: var(--base-padding);
  // }
  // &.bottom-left {
  //   top: calc(var(--base-padding) + 40px + 10px);
  //   left: var(--base-padding);
  // }
  // &.top-right {
  //   top: var(--base-padding);
  //   right: var(--base-padding);
  // }
}

.btn-search-quick {
  // margin: 0 5px 6px 0;
  // position: absolute;
  // left: 50%;
  top: var(--base-padding);
  // z-index: 2;
  font-size: 14px;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.3px;
  height: 32px;
  // transform: translate(-50%, 0);

  &::part(native) {
    color: var(--blue-grey-blue-grey-900, #141518);
    --background: white;
    padding-right: 5px;
    border-radius: var(--border-radius-bigger);
    box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.12);
  }

  ion-icon {
    margin-left: 3px;
  }
}

.map_search.sc-ion-searchbar-ios-h {
  height: 44px;
  width: calc(100% - 100px);
  margin: 0;
  // margin-right: 50px;
  //margin-top: m#{a}x(calc(env(safe-area-inset-top) - 15px), 0px);
  //margin-top: env(safe-area-inset-top);
  padding: 0px;
  padding-bottom: 0px;
  width: 100%;

  input.searchbar-input {
    // background: #fff;
    padding-right: 5px;
    -webkit-padding-start: 38px !important;
    padding-inline-start: 38px !important;
    font-family: PPTelegraf;
    color: var(--blue-grey-blue-grey-900, #141518);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.4px;

    &::placeholder {
      color: var(--blue-grey-blue-grey-400, #7e8395);
    }
  }

  .searchbar-input-container {
    height: 40px;
  }

  &.searchbar-has-value .searchbar-clear-button {
    height: 32px;
    width: 32px;
  }

  ion-icon.searchbar-search-icon {
    left: 10px;
  }

  // input.searchbar-input {
  //   // border: 1px solid white;
  //   border: 1px solid var(--ion-color-primary);
  // }

  &.highlighted {
    input.searchbar-input {
      color: var(--ion-color-primary);
      border: 1px solid var(--ion-color-primary);
    }
    ion-icon {
      color: var(--ion-color-primary);
    }
  }
}

.sc-ion-searchbar-ios-h {
  --border-radius: var(--border-radius-small);
}

.search_top_button {
  height: 43px;
  width: calc(43px + 48px);
  z-index: 10000;
  position: absolute;
  top: 6px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;

    ion-icon {
      width: 23px;
      height: 23px;
      margin-right: 7px;
      // display: block;
    }

    span {
      font-size: 15px;
      // display: block;
      line-height: 15px;
      // width: 10px;
      // height: 10px;
    }
  }

  &::part(native) {
    padding: 0;
    overflow: visible;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
    --border-radius: var(--border-radius-small);
    border: 1px solid white;
  }

  &.highlighted {
    &::part(native) {
      border: 1px solid var(--ion-color-primary);
      color: var(--ion-color-primary);
    }
  }

  &.map_button {
    //margin-top: m#{a}x(calc(env(safe-area-inset-top) - 15px), 0px);
    //margin-top: env(safe-area-inset-top);
    left: 10px;
  }

  .event_amount_badge {
    position: absolute;
    bottom: -6px;
    right: -4px;
    padding: 0px;
    border-radius: var(--border-radius-big);
    padding: 0 6px 0 6px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  }
}

.quick_filters {
  z-index: 1;
  // overflow-x: auto;
  // white-space: nowrap;
  width: 100%;

  ion-button {
    height: 38px;
    margin-right: 4px;

    ion-icon {
      margin-right: 3px;
    }
  }

  ion-button::part(native) {
    -webkit-box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.25);
  }

  ion-button.ion-color-light::part(native) {
    background-color: #fff;
  }
}

// .search_controls_container {
//   .quick_filters {
//     position: absolute;
//     /* both sides in max are added 54px */
//     //top: m#{a}x(calc(env(safe-area-inset-top) - 39px), 54px);
//     top: 56px;
//     left: 10px;
//   }
// }

.search_extras {
  position: absolute;
  bottom: 0px;
  width: 100%;

  .map_center {
    position: absolute;
    right: 0px;
    width: 80px;
    height: 60px;
    top: -70px;

    .map_icon {
      position: absolute;
      bottom: 10px;
      left: 19.5px;
      width: 45px;
      height: 45px;
      color: var(--ion-color-primary);
    }
  }

  .map_placeholder {
    position: absolute;
    top: -30px;
    left: 0px;
    width: 80px;
    height: 30px;
  }
}

.event_search_results {
  z-index: 3;
  width: 100%;
  height: 100%;
  margin: 0%;
  /* both sides in max are added 50px */
  //padding-top: m#{a}x(calc(env(safe-area-inset-top) + 35px), 50px);
  // padding-top: calc(env(safe-area-inset-top) * 0.7 + 50px);
  /*overflow-x: scroll;*/
  display: flex;
  flex-direction: column;

  .map-icon {
    position: static;
    box-shadow: none;
  }

  .loc-button {
    width: 100%;
    font-size: 16px;
    margin: 5px 0 3px 0;

    &::part(native) {
      left: -6px;
      width: 100%;
    }

    .btn-loc-inner {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;

      span {
        font-size: 14px;
        margin-right: 5px;
        margin-top: 1px;
      }

      ion-icon {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }

  ion-card-header {
    margin-top: env(safe-area-inset-top);
    padding: var(--space-xs) var(--base-padding) 0px var(--base-padding);
  }

  .content {
    overflow: hidden;
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none;
    }

    ion-list {
      padding: 0 0 10px 0;
      margin-top: 13px;
    }

    ion-list {
      margin-top: 0px;
    }
  }
}

.thumbnail_list {
  max-height: 50vh;
  overflow-y: auto;

  .thumbnail_list_header {
    font-weight: normal;
    font-size: 16px;

    ion-icon {
      margin-right: 7px;
    }

    &:first-of-type {
      border-top: 1px solid #c8c7cc;
    }
  }
}

.appear_animation {
  -webkit-animation: slidein 0.2s cubic-bezier(0.77, 0.66, 0.53, 0.86);
  animation: slidein 0.2s cubic-bezier(0.77, 0.66, 0.53, 0.86);
}

@keyframes slidein {
  from {
    transform: translateY(90%);
  }
  to {
    transform: translateY(0);
  }
}

.location_search {
  --min-width: 300px;

  .popover-content {
    top: 100px !important;
  }

  .no_results {
    text-align: center;
    padding: 10px;
    margin: 0 10px 10px 10px;
    border-bottom: 1px solid var(--ion-color-primary);
  }

  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px 10px;
    font-size: 13px;

    li {
      padding: 0px 5px;
      margin: 10px 0px;
      border-left: 1px solid var(--ion-color-primary);
    }
  }
}

ion-infinite-scroll-content {
  min-height: 32px;
  .infinite-loading {
    margin-bottom: 0;
  }
}
// .map-page {
//   .search_controls_container {
//     button.searchbar-cancel-button {
//       height: 32px;
//       width: 32px;
//       display: block;
//       position: absolute;
//       right: 10px;
//       top: 10px;
//       border-radius: var(--border-radius-small);
//       margin-top: 5px;
//       margin-right: 5px;
//       background-color: var(--ion-color-step-600);
//       color: transparent;
//       background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjRweCIgdmlld0JveD0iMCAwIDI0IDI0IiB3aWR0aD0iMjRweCIgZmlsbD0iI0ZGRkZGRiI+PHBhdGggZD0iTTAgMGgyNHYyNEgwVjB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTE5IDYuNDFMMTcuNTkgNSAxMiAxMC41OSA2LjQxIDUgNSA2LjQxIDEwLjU5IDEyIDUgMTcuNTkgNi40MSAxOSAxMiAxMy40MSAxNy41OSAxOSAxOSAxNy41OSAxMy40MSAxMiAxOSA2LjQxeiIvPjwvc3ZnPg==");
//       background-repeat: no-repeat;
//       background-size: 17px 17px;
//       background-position: center center;
//     }
//   }
// }

ion-segment.original.switch {
  width: 94px;
  background: var(--ion-color-primary);
  margin: 5px 5px 6px 1px;

  ion-segment-button {
    // flex: none;

    &:not(.segment-button-checked) {
      color: white;
    }

    ion-icon {
      width: 30px;
      height: 30px;
    }

    &::part(native) {
      min-width: auto;
      width: 46px;
      padding: 10px 5px 5px 6px;
    }

    // width: 40px;
    // min-width: auto;
    &.segment-button-checked {
      flex: none;
      // width: 40px;
      // min-width: auto;

      &::part(native) {
        // min-width: auto;
        // width: 46px;
      }
    }
  }
}
