.accept_terms_row {
  margin-top: 5px;
  text-align: center;
  font-size: 14px;

  .input {
    text-align: center;
    width: 55px;
    padding-left: 8px;
    flex-shrink: 0;

    ion-checkbox {
      display: block;
      left: 5px;
      bottom: 1px;
    }
  }

  .text {
    line-height: 1.2em;
  }
}
